/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    maxLength,
} from 'react-admin';
import { useForm } from 'react-final-form';

import Editor from "../components/Editor/Editor";
import SimpleFormToolbar from '../components/SimpleFormToolbar';

/*const PostEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);*/

//const CustomToolbar = props => {
// const CustomToolbar = (props, canDelete) => {
//     console.log(canDelete);
//     return <Toolbar {...props}>
//         <SaveButton />
//         {canDelete && <DeleteButton undoable={false} />}
//     </Toolbar>;
// };

const Title = ({ record }) => {
    return <span>Контентная страница {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
];

export const PageList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <EditButton label="" />
        </Datagrid>
    </List>
);

export const MySimpleForm = props => {
    const [announcement, setAnnouncement] = useState(props?.record?.announcement);
    const [description, setDescription] = useState(props?.record?.description);

    const form = useForm();

    useEffect(() => {
        form.change('announcement', announcement);
    }, [announcement]);

    useEffect(() => {
        form.change('description', description);
    }, [description]);

    return (
        <>
            <TextInput source="h_title" label="Заголовок H1" fullWidth validate={[maxLength(100)]} />
            <Editor text={props?.record?.announcement} setText={setAnnouncement} label="Анонс" />
            <Editor text={props?.record?.description} setText={setDescription} label="Описание" />

            <TextInput source="seo_title" fullWidth/>
            <TextInput source="seo_description" fullWidth/>
            <TextInput source="seo_keyword" fullWidth/>
        </>
    );
};

export const PageEdit = props => {
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar {...props} candelete="false" />}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};