export const onImageDrop = (event, widthSetterFunction, sizeSetterFunction) => {
  if (!event) {
    sizeSetterFunction((prev) => {
      if (Array.isArray(prev)) {
        return [];
      }
      return 0;
    });
    widthSetterFunction((prev) => {
      if (Array.isArray(prev)) {
        return [];
      }
      return 0;
    });
    return;
  }
  if (!Array.isArray(event)) {
    const url = window.URL.createObjectURL(event);
		const img = new Image();
		img.src = url;
		img.onload = () => {
            const currentImageWidth = img.width;
            widthSetterFunction(currentImageWidth);
            sizeSetterFunction(event.size);
        }
  } else {
    event.forEach((image) => {
      const url = window.URL.createObjectURL(image);
      const img = new Image();
      img.src = url;
      img.onload = () => {
          const currentImageWidth = img.width;
          widthSetterFunction((prev) => [...prev, currentImageWidth]);
          sizeSetterFunction((prev) => [...prev, event.size]);
      }
    });
  }
}

export const validateImageWidth = (imagesWidth, validOptions, widthErrorMessage) => {
  if (!Array.isArray(imagesWidth)) {
    return imagesWidth && imagesWidth > Math.max(validOptions) ? widthErrorMessage : undefined; 
  }

  for (let i = 0; i < imagesWidth; i += 1) {
    if (imagesWidth && imagesWidth[i] > Math.max(validOptions)) {
      return widthErrorMessage;
    }
  }

  return undefined;
}

export const validateImageSize = (imagesSize, maxSize, sizeErrorMessage) => {
  if (!Array.isArray(imagesSize)) {
    return imagesSize && imagesSize > maxSize ? sizeErrorMessage : undefined; 
  }

  for (let i = 0; i < imagesSize; i += 1) {
    if (imagesSize && imagesSize > maxSize) {
      return sizeErrorMessage;
    }
  }

  return undefined;
}