import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    TextInput,
    Show,
    SimpleShowLayout,
    BooleanField,
} from 'react-admin';

const Title = ({ record }) => {
    return <span>Подписчик {record ? `"${record.email}"` : ''}</span>;
};

const Filters = [
    <TextInput source="email" label="Search" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const SubscriberList = props => (
    <List bulkActionButtons={false} filters={Filters} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="email" label="Email" />
            <BooleanField source="is_event" label="Мероприятия" />
            <BooleanField source="is_news" label="Новости" />
            <BooleanField source="is_article" label="Статьи" />
            <ShowButton label="" />
        </Datagrid>
    </List>
);

export const SubscriberShow = (props) => {
    return (
      <Show {...props} title={<Title />}>
          <SimpleShowLayout>
              <TextField source="email" label="Email" />
              <BooleanField source="is_event" label="Мероприятия" />
              <BooleanField source="is_news" label="Новости" />
              <BooleanField source="is_article" label="Статьи" />
          </SimpleShowLayout>
      </Show>
)};