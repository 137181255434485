import * as React from 'react';
import { useState } from 'react';
import {
    Toolbar,
    SaveButton,
    DeleteButton,
    Button,
    useNotify,
    useRedirect,
    useDataProvider,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const SimpleFormToolbar = props => {
    const candelete = props?.candelete;
    return <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {candelete !== 'false' && <DeleteButton undoable={false} />}
    </Toolbar>;
};


// TODO: make component reusable
const CustomDeleteButton = ({ record, undoable }) => {
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleDelete = () => {
        dataProvider.delete('product-sale-categories', { id: record.id })
            .then(() => {
                redirect('/product-sale-categories');
                notify(['Категория удалена'], { type: 'success' });
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.status === 422) {
                    notify(['Невозможно удалить категорию, к которой привязан хотя бы один товар'], { type: 'warning' });
                } else {
                    notify([`Ошибка: ${error.message}`], { type: 'warning' });
                }
                setIsLoading(false);
            });
    }
    
    return <Button label="Удалить" onClick={handleDelete} undoable={undoable} disabled={isLoading} style={{ color: 'red' }} />;
};

export const FormToolbarWithCustomDelete = (props) => {
    return <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        <CustomDeleteButton undoable={true} />
    </Toolbar>;
}

export default SimpleFormToolbar;
