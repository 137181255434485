import * as React from 'react';
import { Layout } from 'react-admin';
import theme from './theme';
// import { useSelector } from 'react-redux';
// import { MenuItemLink, getResources } from 'react-admin';
// import DefaultIcon from '@material-ui/icons/ViewList';
// import Divider from '@material-ui/core/Divider';
// import Collapse from '@material-ui/core/Collapse';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';

import TreeMenu from '@bb-tech/ra-treemenu';

// const CustomAppBar = () => {
//     return ('');
// };

// const MenuCollapse = ({ children, ...props }) => {

//     const [open, setOpen] = React.useState(true);

//     const handleClick = () => {
//         setOpen(!open);
//     };

//     return (
//         <>
//             <MenuItemLink
//                 primaryText={props.primaryText}
//                 to={false}
//                 onClick={handleClick}
//                 leftIcon={open ? <ExpandLess /> : <ExpandMore />}
//             />
//             <Collapse in={open} timeout="auto" >
//                 {console.log('_____')}
//                 {console.log(children)}
//                 {children}
//             </Collapse>
//         </>
//     );
// }

// const Menu = () => {
//     const resources = useSelector(getResources);

//     const menu = resources.reduce((result, element) => {
//         let target = result.find(row => row.group_name === element.options.menu_group);

//         let group_name = element.options.menu_group;

//         if(!target) {
//             target = {group_name, values: []};
//             result.push(target);
//         }

//         target.values.push(
//             <MenuItemLink
//                 key={element.name}
//                 to={`/${element.name}`}
//                 primaryText={
//                     (element.options && element.options.label) || element.name
//                 }
//                 leftIcon={
//                     element.icon ? <element.icon/> : <DefaultIcon/>
//                 }
//             />
//         );

//         return result;
//     }, []);

//     console.log(menu)

//     return (
//         <div>
//             <Divider />
//             {
//                 menu.map(resource => {
//                     if (resource.group_name) {
//                         return (
//                             <MenuCollapse primaryText={resource.group_name}>
//                                 {resource.values}
//                             </MenuCollapse>
//                         )
//                     } else {
//                         return resource.values;
//                     }
//                 })
//             }
//             <Divider />
//         </div>
//     );
// };

const CustomLayout = ({ children, ...props }) => {
    return (
        <Layout {...props} theme={theme} menu={TreeMenu}>
            {children}
        </Layout>
    );
};

export default CustomLayout