import * as React from 'react';
import { useState } from 'react';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

import './componentStyles.css';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SyncIcon from '@mui/icons-material/Sync';

const defaultErrorMessage = 'Произошла ошибка. Возможно, в элементе не заполнены обязательные поля.';

const ActivityButton = ({ record, datapath }) => {
    const [isActive, setIsActive] = useState(record.is_active);
    const [isUpdating, setIsUpdating] = useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setIsUpdating(true);
        dataProvider.getOne(datapath, { id: record.id })
            .then(({ data }) => {
                return {
                    id: data.id,
                    data: { ...data, is_active: !data.is_active },
                    previousData: { ...data },
                };
            })
            .then((dataToSend) => {
                dataProvider.update(datapath, dataToSend)
                    .then(({ data }) => {
                        setIsActive(data.is_active);
                        setIsUpdating(false);
                        refresh();
                    })
                    .catch((error) => {
                        const errorMessage = error.message || defaultErrorMessage;
                        console.error(errorMessage);
                        notify([errorMessage], 'error')
                        setIsUpdating(false);
                    });
            })
            .catch((error) => {
                const errorMessage = error.message || defaultErrorMessage;
                console.error(errorMessage);
                notify([errorMessage], 'error')
                setIsUpdating(false);
            });
    }

    const currentIcon = isActive
        ? <CheckIcon onClick={handleClick} className="activity-icon" />
        : <ClearIcon onClick={handleClick} className="activity-icon" />

    return isUpdating ? <SyncIcon className="sync-icon" /> : currentIcon;
}


export default ActivityButton;
