/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    DateField,
    NumberInput,
    useDataProvider,
    useRefresh,
    useNotify,
} from 'react-admin';
import { useForm } from 'react-final-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import Editor from '../components/Editor/Editor';
import TagReferenceInput from '../components/TagReferenceInput';
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import '../components/componentStyles.css';

const Title = ({ record }) => {
    return <span>Бренд {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    <ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,
];

export const BrandList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />

            <ActivityButton
                {...props}
                datapath="brands"
                label="Активность"
            />

            <TextField source="categories_inline" label="Категории" sortable={false} />
            <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />
            <EditButton label="" />
        </Datagrid>
    </List>
);

export const MySimpleForm = props => {
    const [brandRecord, setBrandRecord] = useState({});
    const [brandImageWidth, setBrandImageWidth] = useState(0);
    const [brandImageSize, setBrandImageSize] = useState(0);
    const [description, setDescription] = useState(props?.record?.description);

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const form = useForm();

    useEffect(() => {
        form?.change('description', description);
    }, [description]);

    const successMessage = 'Логотип успешно удален';
    const errorMessage = 'При удалении логотипа возникла ошибка';

    useEffect(() => {
        if (props?.id) {
            dataProvider.getOne('brands', { id: Number(props?.id) })
                .then((res) => {
                    setBrandRecord(res?.data);
                })
                .catch((e) => console.error(e.message));
        }
    }, [props?.id]);

    const onImageDelete = () => {
        dataProvider.update('brands', {
            data: { ...brandRecord, file_id: null, file_ids: [] },
            id: props?.id,
            previousData: brandRecord,
        })
            .then((res) => {
                setBrandRecord(res?.data);
                refresh();
                notify([successMessage], { type: 'success' });
            })
            .catch(() => {
                notify([errorMessage], { type: 'error' });
            });
    }

    const onBrandImageDrop = (e) => onImageDrop(e, setBrandImageWidth, setBrandImageSize);

    const brandImageWidthValidator = () => validateImageWidth(
        brandImageWidth,
        [336],
        'Изображение не может быть шире 336 пикселей'
    );

    const brandImageSizeValidator = () => validateImageSize(
        brandImageSize,
        200000,
        'Изображение не может весить более 200кб'
    );

    const brandImageValidator = [brandImageWidthValidator, brandImageSizeValidator];

    return (
        <>
            <TextField source="title" label="Название" />
            <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />
            <DateField source="create_at" label="Дата и время создания" showTime />
            <TextField source="guid" label="GUID" />
            <TextField source="categories_inline" label="Категории" />

            <Editor text={props?.record?.description} setText={setDescription} label="Описание" />
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth />
            <NumberInput source="show_in_search" label="Выводить в поиске" onKeyDown={(e) => ['+', '-', 'e', 'E'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} fullWidth min="0" />

            <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onBrandImageDrop} placeholder={<p>Перетащите сюда логотип шириной <b>336px</b> весом до <b>200кб</b></p>} fullWidth validate={brandImageValidator}>
                <ImageField source="src" label="Логотип" />
            </ImageInput>

            <ImageField source="file.file_path" label="Логотип" />

            {brandRecord?.file_id && (
                <button
                    type="button"
                    className="remove-button delete-logo-button MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-6 ra-delete-button RaDeleteWithConfirmButton-deleteButton-148 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={onImageDelete}
                >
                    <RemoveCircleOutlineIcon />
                    <span>Удалить логотип</span>
                </button>
            )}

            <TagReferenceInput
                label="Теги"
                reference="tags"
                source="tags"
                fullWidth
            />

            <TextInput source="page.seo_title" label="SEO Title" fullWidth />
            <TextInput source="page.seo_description" label="SEO Description" fullWidth />
            <TextInput source="page.seo_keyword" label="SEO Keywords" fullWidth />
        </>
    )
};

export const BrandEdit = props => {
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar {...props} />}>
            <MySimpleForm type='update' />
        </SimpleForm>
    </Edit>
};