import { Mark, mergeAttributes } from '@tiptap/core';

const AddGray = Mark.create({
  name: 'spanGray',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      class: {
        default: "gray"
      }
    };
  },

  parseHTML() {
    return [
      { tag: 'span[class="gray"]' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addCommands() {
    return {
      toggleAddGray: () => ({ commands }) => {
        return commands.toggleMark(this.name);
      },
    }
  }
});

export default AddGray;
