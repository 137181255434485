import React, { useState, useCallback } from 'react';
// import { useFormState } from 'react-final-form';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import TagQuickCreateButton from './TagQuickCreateButton';
import { itemsPerPage } from '../utils/variables';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

// const spySubscription = { values: true };

const TagReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    // const { values } = useFormState({ subscription: spySubscription });
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    return (
        <div className={classes.root}>
            {/*<ReferenceInput key={version} {...props}>
                <SelectInput optionText="title" />
            </ReferenceInput>*/}

            <ReferenceArrayInput perPage={itemsPerPage} key={version} {...props}>
                <AutocompleteArrayInput optionText="title" optionValue="id"/>
            </ReferenceArrayInput>

            <TagQuickCreateButton onChange={handleChange} />
        </div>
    );
};

export default TagReferenceInput;
