/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useDataProvider, useRefresh, useNotify, TextInput } from 'react-admin';
import has from 'lodash/has';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BackupIcon from '@mui/icons-material/Backup';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useForm } from 'react-final-form';

import './componentStyles.css';
import Editor from '../components/Editor/Editor';

const ImageFeed = ({ record }) => {
    const [fileItems, setFileItems] = useState(record?.files || []);
    const [updateCount, setUpdateCount] = useState(0);
    const [currentInputField, setCurrentInputField] = useState(null);
    const [text, setText] = useState('');
    const [linkValues, setLinkValues] = useState({});
    
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const form = useForm();

    useEffect(() => {
        const updatedItems = record?.files?.map((el, i) => {
            if (i === currentInputField) {
                el.title = text;
            }
            if (linkValues?.[i]) {
                el.link = linkValues?.[i];
            }
            return el;
        });

        form.change('files', updatedItems);
    }, [text]);

    const handleLinkChange = (e, id) => {
        const currentValue = e.target.value;
        setLinkValues({ ...linkValues, [id]: currentValue });
    }

    useEffect(() => {
        if (record.files) {
            setFileItems(record?.files.map((el, i) => ({ ...el, sort: i + 1 })));
            setLinkValues(record?.files.reduce((acc, el, index) => {
                acc[index] = el.link;
                return acc;
            }, {}))
        }
    }, [record])

    useEffect(() => {
        if (updateCount > 0) {     
            const updatedRecord = {
                id: record.id,
                data: { ...record, files: fileItems },
                previousData: { ...record },
            };

            dataProvider.update('sliders', updatedRecord)
                .then(() => {
                    refresh();
                    notify('Порядок изображений успешно изменен', { type: 'success', multiLine: true });
                })
                .catch((e) => console.error(e));
        }
    }, [updateCount])
  
    const calculateDragConstraints = (index, length) => {
        const step = 200;
        switch (index) {
            case 1:
                return { top: 0, bottom: step };
            case length:
                return { top: -step, bottom: 0 };
            default:
                return { top: -step, bottom: step };
        }
    }

    const handleRemove = (order) => {
        const removedItem = fileItems.find((el) => el.sort === order);
        const removedItemId = removedItem.file_id;

        dataProvider
            .delete('slider-files', { id: `${record.id}/${removedItemId}` })
            .then(() => {
                dataProvider.getOne('sliders', { id: record.id })
                    .then(({ data }) => setFileItems(data.files))
                    .catch((error) => console.log(error.message));
            })
    }

    const handleDownwardClick = (item) => {
        const { sort: order } = item;

        const itemToMove1 = { ...item, sort: order + 1 };
        const itemToMove2 = fileItems.find((item) => item.sort === order + 1);
        itemToMove2.sort = order;

        const otherItems = fileItems.filter((item) => item.sort !== order && item.sort !== order + 1);
        const updatedItems = [...otherItems, itemToMove1, itemToMove2].sort((a, b) => a.sort - b.sort);

        setFileItems(updatedItems);
        setUpdateCount((prev) => prev + 1);
    }

    const handleUpwardClick = (item) => {
        const { sort: order } = item;
        
        const itemToMove1 = { ...item, sort: order - 1 };
        const itemToMove2 = fileItems.find((item) => item.sort === order - 1);
        itemToMove2.sort = order;

        const otherItems = fileItems.filter((item) => item.sort !== order && item.sort !== order - 1);
        const updatedItems = [...otherItems, itemToMove1, itemToMove2].sort((a, b) => a.sort - b.sort);

        setFileItems(updatedItems);
        setUpdateCount((prev) => prev + 1);
    }

    return (
        <ul className="images-container">
            {
                record && fileItems
                    .sort((a, b) => a.sort - b.sort)
                    .map((el, i) => ({ ...el, sort: i + 1 }))
                    .map((item) => {
                        const sortIndexes = fileItems.map((el) => el.sort);
                        const minSort = Math.min(...sortIndexes);
                        const maxSort = Math.max(...sortIndexes);
                        const upwardIconEnabled = item.sort > minSort;
                        const downwardIconEnabled = item.sort < maxSort;

                        return (
                            <motion.li
                                key={item.file_id}
                                drag="y"
                                dragConstraints={calculateDragConstraints(item.sort, fileItems.length)}
                                dragSnapToOrigin
                                dragElastic={0}
                                className="draggable-field"
                                onDragEnd={(e, info) => {
                                    const order = item.sort;
                                    const { y } = info.offset;
                                    const direction = y > 0 ? 'down' : 'up';

                                    if (y > 0 && y < 100) {
                                        return;
                                    }

                                    if (y < 0 && y > -100) {
                                        return;
                                    }

                                    if (direction === 'up') {
                                        if (order === 1) {
                                            return;
                                        }
                                        
                                        const itemToMove1 = { ...item, sort: order - 1 };
                                        const itemToMove2 = fileItems.find((item) => item.sort === order - 1);
                                        itemToMove2.sort = order;

                                        const otherItems = fileItems.filter((item) => item.sort !== order && item.sort !== order - 1);
                                        const updatedItems = [...otherItems, itemToMove1, itemToMove2].sort((a, b) => a.sort - b.sort);

                                        setFileItems(updatedItems);
                                        setUpdateCount((prev) => prev + 1);
                                    }
                                    
                                    if (direction === 'down') {
                                        if (order === fileItems.length) {
                                            return;
                                        }

                                        const itemToMove1 = { ...item, sort: order + 1 };
                                        const itemToMove2 = fileItems.find((item) => item.sort === order + 1);
                                        itemToMove2.sort = order;

                                        const otherItems = fileItems.filter((item) => item.sort !== order && item.sort !== order + 1);
                                        const updatedItems = [...otherItems, itemToMove1, itemToMove2].sort((a, b) => a.sort - b.sort);

                                        setFileItems(updatedItems);
                                        setUpdateCount((prev) => prev + 1);
                                    }
                                }}
                            >
                                <div className="draggable-image-wrapper">
                                    <div className="draggable-image-content-wrapper">
                                        <div className="arrows-container">
                                            {upwardIconEnabled ? <ArrowUpwardIcon onClick={() => handleUpwardClick(item)} /> : <ArrowUpwardIcon onClick={() => {}} color="disabled" />}
                                            {downwardIconEnabled ? <ArrowDownwardIcon onClick={() => handleDownwardClick(item)} /> : <ArrowDownwardIcon onClick={() => {}} color="disabled" />}
                                        </div>
                                        {
                                            has(item, 'file.file_path')
                                                ? (<img
                                                    src={item.file.file_path}
                                                    alt=""
                                                    className="draggable-image"
                                                />)
                                                : (
                                                    <>
                                                        <BackupIcon />
                                                        <span>Uploading image...</span>
                                                    </>
                                                )
                                        }
                                    </div>
                                    <div className="draggable-field-wrapper">
                                        <div
                                            onClick={() => {
                                                if (item.sort - 1 !== currentInputField) {
                                                    setCurrentInputField(item.sort - 1);
                                                    setText(fileItems?.[item.sort - 1]?.title || '');
                                                }
                                        }}>
                                            <Editor
                                                text={record?.files?.[item.sort - 1]?.title || ''}
                                                setText={setText}
                                                label={`Название ${item.sort}`}
                                                style={{ marginBottom: '-18px' }}
                                            />
                                        </div>
                                        <TextInput
                                            source={`files[${item.sort - 1}].link`}
                                            label={`Ссылка ${item.sort}`}
                                            style={{ marginBottom: '-18px' }}
                                            onChange={(e) => handleLinkChange(e, item.sort - 1)}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleRemove(item.sort)}
                                        className="remove-button"
                                        title="Удалить слайд"
                                    >
                                        <RemoveCircleOutlineIcon />
                                    </button>
                                </div>
                            </motion.li>
                        )
                    }
                )
            }
        </ul>
    )
};

export default ImageFeed;