/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Button,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required,
    ReferenceArrayInput,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    SelectArrayInput,
    useDataProvider,
    maxLength,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';

import Editor from "../components/Editor/Editor";
import ActivityButton from "../components/ActivityButton";
import ModalWindow from "../components/ModalWindow";
import ImageFeed from "../components/ImageFeed";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import urlValidator from '../utils/urlValidator';
import { itemsPerPage } from "../utils/variables";

const Title = ({ record }) => {
    return <span>Услуга {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const ServiceList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <TextField source="sort" label="Сортировка" />
            <ActivityButton
                {...props}
                datapath="services"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [activeSliderId, setActiveSliderId] = useState(rest?.record?.slider_id);
    const [sliderRecord, setSliderRecord] = useState(null);
    const [sliderImagesWidth, setSliderImagesWidth] = useState([]);
    const [sliderImagesSize, setSliderImagesSize] = useState([]);
    const [serviceImageWidth, setServiceImageWidth] = useState(0);
    const [serviceImageSize, setServiceImageSize] = useState(0);
    const [description, setDescription] = useState(rest?.record?.description);
    const [workTime, setWorkTime] = useState(rest?.record?.work_time);

    const form = useForm();

    useEffect(() => {
        form.change('description', description);
    }, [description]);

    useEffect(() => {
        form.change('work_time', workTime);
    }, [workTime]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getOne('sliders', { id: activeSliderId })
            .then((response) => {
                setSliderRecord(response.data);
            })
            .catch((e) => console.log(e.message));
    }, [activeSliderId]);

    useEffect(() => {
        if (!modalIsOpened) {
            setSliderImagesWidth([]);
        }
    }, [modalIsOpened]);

    const handleSliderChange = (e) => {
        setActiveSliderId(e.target.value);
    }

    const handleModalEditLaunch = () => {
        setModalType('edit');
        setModalIsOpened(true);
    } 
    const handleModalCreateLaunch = () => {
        setModalType('create');
        setModalIsOpened(true);
    }

    const onSliderImagesDrop = (e) => onImageDrop(e, setSliderImagesWidth, setSliderImagesSize);
    const onServiceImageDrop = (e) => onImageDrop(e, setServiceImageWidth, setServiceImageSize);

    const sliderImagesWidthValidator = () => validateImageWidth(
        sliderImagesWidth,
        [976],
        'Изображение не может быть шире 976 пикселей'
    );
    
    const sliderImagesSizeValidator = () => validateImageSize(
        sliderImagesSize,
        500000,
        'Изображение не может весить более 500кб'
    )

    const serviceImageWidthValidator = () => validateImageWidth(
        serviceImageWidth,
        [976],
        'Изображение не может быть шире 976 пикселей'
    );

    const serviceImageSizeValidator = () => validateImageSize(
        serviceImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const sliderImagesValidator = [sliderImagesWidthValidator, sliderImagesSizeValidator];
    const serviceImageValidator = [serviceImageWidthValidator, serviceImageSizeValidator];

    //return in () jsx
    return (
        <>
            <TextInput source="title" label="Название" validate={[required(), maxLength(255)]} fullWidth/>
            <TextInput source="sub_title" multiline label="Подзаголовок" fullWidth />
            <Editor text={rest?.record?.description} setText={setDescription} label="Описание" />
            <Editor text={rest?.record?.work_time} setText={setWorkTime} label="Часы работы" />

            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

            <ReferenceInput label="Слайдер" reference="sliders" source="slider_id" onChange={handleSliderChange} perPage={itemsPerPage} allowEmpty emptyText="— Очистить —" emptyValue={null} validate={[required()]} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <Button
                onClick={handleModalCreateLaunch}
                label="Новый слайдер"
            />
            <Button
                onClick={handleModalEditLaunch}
                label="Редактировать слайдер"
            />

            <ModalWindow modalIsOpened={modalIsOpened} setModalIsOpened={setModalIsOpened}>
                {
                    modalType === 'create' && (
                        <Create basePath="/sliders" resource="sliders" title=" -> Новый слайдер">
                            <SimpleForm redirect={false}>
                                <TextInput source="title" label="Название" validate={[required()]} fullWidth/>
                                <RichTextInput source="description" multiline label="Описание" fullWidth/>
                                <TextInput source="link" validate={urlValidator} label="Ссылка на подробную страницу" fullWidth/>
                                <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

                                <ReferenceInput reference="pages" source="page_id" label="Контентная страница" fullWidth allowEmpty>
                                    <SelectInput optionText="title" optionValue="id" />
                                </ReferenceInput>

                                <ImageInput multiple source="pictures" label="Загрузка изображений" accept="image/png,image/jpeg" onChange={onSliderImagesDrop} placeholder={<p>Перетащите сюда изображение шириной <b>976px</b> весом до <b>500кб</b></p>} validate={[required(), sliderImagesValidator]} fullWidth>
                                    <ImageField source="src" label="Изображение" />
                                </ImageInput>
                            </SimpleForm>
                        </Create>
                    )
                }
                {
                    modalType === 'edit' && (
                        <Edit basePath={`/sliders/${activeSliderId}`} resource="sliders" id={activeSliderId} title=" -> Редактировать слайдер">
                            <SimpleForm record={sliderRecord} redirect={false} toolbar={<SimpleFormToolbar alwaysEnableSaveButton />}>
                                <TextInput source="title" label="Название" validate={[required()]} fullWidth/>
                                <RichTextInput source="description" multiline label="Описание" fullWidth/>
                                <TextInput source="link" validate={urlValidator} label="Ссылка на подробную страницу" fullWidth/>
                                <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

                                <ReferenceInput reference="pages" source="page_id" label="Контентная страница" fullWidth allowEmpty>
                                    <SelectInput optionText="title" optionValue="id" />
                                </ReferenceInput>

                                <ImageInput multiple source="pictures" label="Загрузка изображений" accept="image/png,image/jpeg" onChange={onSliderImagesDrop} placeholder={<p>Перетащите сюда изображение шириной <b>976px</b> весом до <b>500кб</b></p>} validate={sliderImagesValidator} fullWidth>
                                    <ImageField source="src" label="Изображение" />
                                </ImageInput>

                                <ImageFeed record={sliderRecord} />
                            </SimpleForm>
                        </Edit>
                    )
                }
            </ModalWindow>

            <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onServiceImageDrop} placeholder={<p>Перетащите сюда изображение шириной <b>976px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), serviceImageValidator]) || serviceImageValidator}>
                <ImageField source="src" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="file.file_path" label="Изображение" />}

            <ReferenceArrayInput source="restaurants" reference="restaurants" label="Рестораны" perPage={itemsPerPage} fullWidth>
                <SelectArrayInput optionText="title" optionValue="id" />
            </ReferenceArrayInput>
            <ArrayInput source="phones" label="Телефоны" fullWidth>
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                    <TextInput source="phone" label="Телефон" fullWidth validate={[maxLength(50)]} />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="locations" label="Местоположение" fullWidth>
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                    <NumberInput source="pavilion" fullWidth label="Павильон" min="1" max="3"/>
                    <NumberInput source="floor" fullWidth label="Этаж" min="1" max="3"/>
                    <TextInput source="stand" fullWidth label="Стенд"/>
                    <TextInput source="raw" fullWidth label="Место"/>
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput source="page.seo_title" label="SEO Title" fullWidth/>
            <TextInput source="page.seo_description" label="SEO Description" fullWidth/>
            <TextInput source="page.seo_keyword" label="SEO Keywords" fullWidth/>

            <NumberInput source="sort" label="Порядок вывода услуги на общей странице" validate={[required()]} min="0" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} fullWidth />
        </>
    );
}

export const ServiceEdit = props => {
    /*props means "name='123' fdsgdfg='dfgdfhgfgh'"*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar candelete="false"/>}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};

export const ServiceCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);