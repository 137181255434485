import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ImageIcon from '@mui/icons-material/Image';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CircleIcon from '@mui/icons-material/Circle';
import TableChartIcon from '@mui/icons-material/TableChart';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import AddClass from './AddClass';
import AddHighlight from './AddHighlight';
import AddImage from './AddImage';
import AddSup from './AddSup';
import AddGray from './AddGray';
import AddSmallLetters from './AddSmallLetters';
import Gallery from './Gallery';
import './editorClasses.css';

const Editor = ({
  text, setText, label,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      AddClass,
      AddHighlight,
      AddImage,
      Gallery,
      Table,
      TableCell,
      TableRow,
      TableHeader,
      AddSup,
      AddGray,
      AddSmallLetters,
    ],
    content: text,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setText(html);
    },
  });

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadData, setUploadData] = useState({});
  const [tableOptionsOn, setTableOptionsOn] = useState(false);
  const [fontSizeOptionsOn, setFontSizeOptionsOn] = useState(false);
  const [chosenFontSize, setChosenFontSize] = useState('p');
  const singleImageInputRef = useRef(null);
  // const multipleImageInputRef = useRef(null);

  useEffect(() => {
    setFontSizeOptionsOn(false);
  }, [chosenFontSize]);

  useEffect(() => {
    if (uploadedImages?.length) {
      const defaultData = uploadedImages.map((image) => ({ src: image }));
      setUploadData(defaultData);
    }
  }, [uploadedImages]);

  const handleOpenSingleImageInput = (e) => {
    e.preventDefault();
    singleImageInputRef?.current?.click();
  };
  // const handleOpenMultipleImageInput = () => multipleImageInputRef?.current?.click();

  const handleClearImageData = () => setUploadedImages([]);

  const handleChangeUploadData = (id, field, value) => {
    let fieldName;
    switch (field) {
      case 'name':
        fieldName = 'data-name';
        break;
      case 'copyright':
        fieldName = 'data-copyright';
        break;
      default:
        fieldName = field;
        break;
    }

    const newData = uploadData.find((el) => el.src === id);
    const rest = uploadData.filter((el) => el.src !== id);
    newData[fieldName] = value;
    setUploadData([...rest, newData ]);
  }

  const setLink = useCallback((e) => {
    e.preventDefault();
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div>
      <div className="button-menu">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setFontSizeOptionsOn((prev) => !prev); 
          }}
          className="font-size__popup"
          title="Размер шрифта"
        >
          <span className="button-wrapper">
            {editor.isActive('paragraph') && 'Обычный текст'}
            {editor.isActive('heading', { level: 1 }) && <span className="heading-2">Заголовок 1</span>}
            {editor.isActive('heading', { level: 2 }) && <span className="heading-2">Заголовок 2</span>}
            {editor.isActive('heading', { level: 3 }) && <span className="heading-2">Заголовок 3</span>}
            {(!editor.isActive('heading') && !editor.isActive('paragraph')) && 'Размер шрифта'}
            <UnfoldMoreIcon />
          </span>
          {
            fontSizeOptionsOn && (
              <div className="font-size__options">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setChosenFontSize('h1');
                    editor.chain().focus().toggleHeading({ level: 1 }).run();
                  }}
                  className="heading-1"
                >
                  Заголовок 1
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setChosenFontSize('h2');
                    editor.chain().focus().toggleHeading({ level: 2 }).run();
                  }}
                  className="heading-2"
                >
                  Заголовок 2
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setChosenFontSize('h3');
                    editor.chain().focus().toggleHeading({ level: 3 }).run();
                  }}
                  className="heading-3"
                >
                  Заголовок 3
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setChosenFontSize('p');
                    editor.chain().focus().setParagraph().run();
                  }}
                  style={{ fontSize: '12px', fontWeight: 'normal' }}
                >
                  Обычный текст
                </button>
              </div>
            )
          }
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          className={editor.isActive('bold') ? 'is-active' : ''}
          title="Жирный"
        >
          <FormatBoldIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          className={editor.isActive('italic') ? 'is-active' : ''}
          title="Наклонный"
        >
          <FormatItalicIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleUnderline().run();
          }}
          className={editor.isActive('underline') ? 'is-active' : ''}
          title="Подчеркивание"
        >
          <FormatUnderlinedIcon />
        </button>
        <button
          type="button"
          onClick={setLink}
          className={editor.isActive('link') ? 'is-active' : ''}
          title="Добавить ссылку"
        >
          <AddLinkIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().unsetLink().run();
          }}
          disabled={!editor.isActive('link')}
          title="Удалить ссылку"
        >
          <LinkOffIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
          title="Нумерованный список"
        >
          <FormatListNumberedIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
          title="Список с буллитами"
        >
          <FormatListBulletedIcon />
        </button>
        <button
          type="button"
          onClick={handleOpenSingleImageInput}
          title="Загрузить картинку"
        >
          <ImageIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('left').run()
          }}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
          title="Выравнивание слева"
        >
          <FormatAlignLeftIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('center').run();
          }}
          className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
          title="Выравнивание по центру"
        >
          <FormatAlignCenterIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('right').run();
          }}
          className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
          title="Выравнивание справа"
        >
          <FormatAlignRightIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('justify').run()
          }}
          className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
          title="Выравнивание по краям"
        >
          <FormatAlignJustifyIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleAddHighlight().run();
          }}
          title="Выделить жёлтым"
          className={editor.isActive('spanHighlight') ? 'is-active' : ''}
        >
          <BorderColorIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleAddClass().run();
          }}
          title="Добавить класс: желтый круг"
          className={editor.isActive('yellowCircleClass') ? 'is-active' : ''}
        >
          <CircleIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setTableOptionsOn((prev) => !prev);
          }}
          title="Показать элементы управления таблицами"
        >
          <TableChartIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().undo().run();
          }}
          title="Шаг назад"
        >
          <UndoIcon />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().redo().run();
          }}
          title="Шаг вперед"
        >
          <RedoIcon />
        </button>
      </div>
      {
          tableOptionsOn && (
            <>
              <hr />
              <div className="table-controls">
                <div className="table-controls__section">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: true }).run();
                    }}
                  >
                    Новая таблица
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().deleteTable().run()
                    }}
                    disabled={!editor.can().deleteTable()}
                  >
                    Удалить таблицу
                  </button>
                </div>
                <div className="table-controls__section">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().addColumnBefore().run();
                    }}
                    disabled={!editor.can().addColumnBefore()}
                  >
                    Колонка до
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().addColumnAfter().run();
                    }}
                    disabled={!editor.can().addColumnAfter()}
                  >
                    Колонка после
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().deleteColumn().run();
                    }}
                    disabled={!editor.can().deleteColumn()}
                  >
                    Удалить колонку
                  </button>
                </div>
                <div className="table-controls__section">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().addRowBefore().run();
                    }}
                    disabled={!editor.can().addRowBefore()}
                  >
                    Ряд до
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().addRowAfter().run();
                    }}
                    disabled={!editor.can().addRowAfter()}
                  >
                    Ряд после
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().deleteRow().run();
                    }}
                    disabled={!editor.can().deleteRow()}
                  >
                    Удалить ряд
                  </button>
                </div>
                <div className="table-controls__section">
                  {/* <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().toggleHeaderCell().run();
                    }}
                    disabled={!editor.can().toggleHeaderCell()}
                  >
                    Заглавная ячейка
                  </button> */}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().mergeCells().run();
                    }}
                    disabled={!editor.can().mergeCells()}
                  >
                    Слияние ячеек
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      editor.chain().focus().splitCell().run();
                    }}
                    disabled={!editor.can().splitCell()}
                  >
                    Разделение ячеек
                  </button>
                </div>
                <div className="table-controls__section">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().toggleAddSup().run();
                    }}
                    className={editor.isActive('supMark') ? 'is-active' : ''}
                  >
                    Надстрочный символ
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().toggleAddGray().run();
                    }}
                    className={editor.isActive('spanGray') ? 'is-active' : ''}
                  >
                    Серый текст
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor.chain().focus().toggleAddSmallLetters().run();
                    }}
                    className={editor.isActive('spanSmallLetters') ? 'is-active' : ''}
                  >
                    Уменьшенный шрифт
                  </button>
                </div>
              </div>
            </>
          )
        }
      {
        uploadedImages?.length ? (
          <div className={`image-data__modal ${uploadedImages.length === 1 ? 'image-data__modal_singular' : 'image-data__modal_multiple'}`}>
            <div className={`image-data__section-wrapper ${uploadedImages.length === 1 ? 'image-data__section-wrapper_singular' : 'image-data__section-wrapper_multiple'}`}>
            {
              uploadedImages?.map((el) => (
                <div className={`image-data__section ${uploadedImages.length === 1 ? 'image-data__section_singular' : 'image-data__section_multiple'}`} key={el}>
                  <img src={el} alt="" />
                  <form
                    className="section__form"
                  >
                    <label htmlFor="name">Название картинки</label>
                    <input onChange={(e) => handleChangeUploadData(el, 'name', e.target.value)} id="name" type="text" />
                    <label htmlFor="title">Подсказка</label>
                    <input onChange={(e) => handleChangeUploadData(el, 'title', e.target.value)} id="title" type="text" />
                    <label htmlFor="copyright">Копирайт*</label>
                    <input onChange={(e) => handleChangeUploadData(el, 'copyright', e.target.value)} id="copyright" type="text" />
                  </form>
                </div>
              ))
            }
            </div>
            <div className="modal__button-group">
              <button
                onClick={() => {
                  if (uploadedImages?.length === 1) {
                    const data = uploadData[0];
                    editor.chain().focus().setAddImage({
                      src: data.src || '',
                      title: data.title || '',
                      'data-copyright': data['data-copyright'] || '',
                      'data-name': data['data-name'] || '',
                    }).run();
                  } else {
                    editor.chain().focus().setGallery().run();
                  }
                  handleClearImageData();
                }}
                type="button">
                  Сохранить
              </button>
              <button type="button" onClick={handleClearImageData}>Отмена</button>
            </div>
          </div>
        ) : null
      }
      <EditorContent editor={editor}>
        <p className="editor-label">{label}</p>
      </EditorContent>
      <form style={{ display: 'none' }}>
        <input onChange={(e) => {
            const inputField = e.target;
            const image = inputField.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(image);

            reader.onloadend = function () {
              const imageAsString = reader.result;
              setUploadedImages([imageAsString]);
            };

            reader.onerror = function (error) {
              console.log('Upload error: ', error);
            };
        }} type="file" ref={singleImageInputRef} />
        {/* <input multiple onChange={(e) => {
            const inputField = e.target;
            const images = Object.values(inputField.files);

            images.forEach((image) => {
              const reader = new FileReader();
              reader.readAsDataURL(image);

              reader.onloadend = function () {
                const imageAsString = reader.result;
                setUploadedImages((prev) => [...prev, imageAsString]);
              };

              reader.onerror = function (error) {
                console.log('Upload error: ', error);
              };
            });
          }} type="file" ref={multipleImageInputRef} /> */}
      </form>
    </div>
  )
}

export default Editor