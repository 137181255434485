import dataProvider from './dataProvider';

const apiUrl = `${process.env.REACT_APP_API_URL}`;
// загружает картинку на cdn
const uploadImage = (blob, imageName) => {
    const url = `${apiUrl}/file-upload`;
    var formData = new FormData();
    formData.append("file", new Blob([blob]));

    const token  = JSON.parse(localStorage.getItem('auth')).access_token;

    return fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "Authorization": `Bearer ${token}`
        },
    }).then((response) => {
        return response.json();
    });
};

// возвращает картинку в бинарном виде
const readImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file.rawFile);

        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
    });
};

// загрузка файла при создании или редактировании
const uploadPicture = (dataProvider, resource, params, action) => {
    console.log(params);
    if (resource === 'marketing-collections') {
        params.data.marketing_campaign_id = 1;
    }

    //todo save marketing pictures
    let pictures = [];
    if (resource === 'marketing-campaigns' || resource === 'banners') {
        if (params.data.picture_desk) {
            pictures.push(params.data.picture_desk);
        }

        if (params.data.picture_mob) {
            pictures.push(params.data.picture_mob);
        }
    } else {
        pictures = params.data.pictures ? params.data.pictures : [] // массив загруженных картинок в input file
        const picture = params.data.picture;

        if (picture) {
            console.log('picture', picture);
            pictures.push(picture);
        }
        if (params.data.picture_square) {
            pictures.push(params.data.picture_square);
        }
        if (params.data.picture_main) {
            pictures.push(params.data.picture_main);
        }
    }

    if (!pictures) {
        if (action === 'create') {
            return dataProvider.create(resource, params);
        } else {
            return dataProvider.update(resource, params);
        }
    }

    console.log('pictures', pictures);

    return Promise
        .all(pictures.map((pic) =>
            readImage(pic)
                .then((blob) => uploadImage(blob, pic.rawFile.name))
        ))
        .then((success) => { // здесь id картинки которая загружена на cdn
            console.log('success', success);
            console.log('success.length', success.length);
            console.log('params', params);
            if (resource === 'marketing-campaigns' || resource === 'banners') {
                success?.forEach((item, index) => {
                    if (resource === 'banners') {
                        if (params.data.picture_desk && !params.data.picture_mob) {
                            params.data.desktop_file_id = item.id;
                        }
                        if (params.data.picture_mob && !params.data.picture_desk) {
                            params.data.mobile_file_id = item.id;
                        }

                        if (success.length === 2) {
                            if (index === 0) {
                                params.data.desktop_file_id = item.id;
                            } else {
                                params.data.mobile_file_id = item.id;
                            }
                        }    
                    } else {
                        if (params.data.picture_desk && !params.data.picture_mob) {
                            params.data.first_slide_desktop_file_id = item.id;
                        }
                        if (params.data.picture_mob && !params.data.picture_desk) {
                            params.data.first_slide_mobile_file_id = item.id;
                        }

                        if (success.length === 2) {
                            if (index === 0) {
                                params.data.first_slide_desktop_file_id = item.id;
                            } else {
                                params.data.first_slide_mobile_file_id = item.id;
                            }
                        }    
                    }
                    
                    console.log('item', item);
                    console.log('index', index);
                });
            } else {
                if (resource === 'materials') {
                    success?.forEach((item, index) => {
                        if (params.data.picture && !params.data.picture_square && !params.data.picture_main) {
                            console.log('it is picture!');
                            params.data.file_id = item.id;
                        }
                        if (params.data.picture_square && !params.data.picture && !params.data.picture_main) {
                            console.log('it is square picture!')
                            params.data.square_file_id = item.id;
                        }
                        if (params.data.picture_main && !params.data.picture && !params.data.picture_square) {
                            console.log('it is main picture!');
                            params.data.main_file_id = item.id;
                        }

                        if (success.length === 2) {
                            if (!params.data.picture) {
                                if (index === 0) {
                                    params.data.main_file_id = item.id;
                                } else {
                                    params.data.square_file_id = item.id;
                                }
                            }
                            if (!params.data.picture_square) {
                                if (index === 1) {
                                    params.data.main_file_id = item.id;
                                } else {
                                    params.data.file_id = item.id; 
                                }
                            }
                            if (!params.data.picture_main) {
                                if (index === 1) {
                                    params.data.square_file_id = item.id;
                                } else {
                                    params.data.file_id = item.id; 
                                }
                            }
                        }

                        if (success.length === 3) {
                            if (index === 2) {
                                params.data.main_file_id = item.id;
                            } else if (index === 1) {
                                params.data.square_file_id = item.id;
                            } else if (index === 0) {
                                params.data.file_id = item.id;
                            }
                        }
                        console.log('item', item);
                        console.log('index', index);
                    });

                    console.log('params.file_id', params.data.file_id);
                    console.log('params.square_file_id', params.data.square_file_id);
                    console.log('params.main_file_id', params.data.main_file_id);
                } else {
                    if (success.length === 1 && resource !== 'sliders') {
                        success.forEach((item) => {
                            //добавили ид
                            params.data.file_id = item.id;
                        });
                    } else {
                        if (resource !== 'tags') {
                            const currentFiles = params.data.files;
                            const addedItemIds = success.map((el) => el.id);
                            params.data.file_ids = addedItemIds;
                            const hasAddedFiles = addedItemIds.length !== 0;
                            
                            if (hasAddedFiles) {
                                if (!currentFiles) {
                                    params.data.files = addedItemIds.map((el, i) => ({ file_id: el, sort: i + 1 })); 
                                } else {
                                    let index = currentFiles.length;
                                    let addedFiles = [];
                                    addedItemIds.forEach((el) => {
                                        addedFiles.push({ file_id: el, sort: index + 1 });
                                        index += 1;
                                    });
        
                                    params.data.files = [...currentFiles, ...addedFiles]; 
                                }
                            }
    
                            if (resource === 'speakers') {
                                if (params?.data?.file?.id) {
                                    params.data.file_id = params.data.file.id;
                                }
                            }
    
                            console.log('success', success);
                            console.log('params.data.file_ids', params.data.file_ids);
                        }
                    }
                }
            }
        })
        .then(() => {
            if (resource === 'shops') {
                const { sales } = params.data;

                if (!sales) {
                    params.data.sales = [];
                    return;
                }

                const salesWithPictures = sales.filter((el) => el.picture);
                const salesWithoutPictures = sales.filter((el) => !el.picture);

                return Promise.all(
                    salesWithPictures.map((sale) => readImage(sale.picture).then((blob) => uploadImage(blob, sale.picture.rawFile.name)))
                ).then((success) => {
                    console.log('success', success);

                    const clearedSalesWithPictures = salesWithPictures.map((el, i) => {
                        const { picture, ...rest } = el;
                        return {
                            ...rest,
                            file_id: success[i].id,
                            shop_id: Number(params.id),
                            discount: String(rest.discount),
                        };
                    });

                    const clearedSalesWithoutPictures = salesWithoutPictures.map((el) => {
                        return {
                            ...el,
                            shop_id: Number(params.id),
                            discount: String(el.discount),
                        };
                    });

                    const result = [...clearedSalesWithPictures, ...clearedSalesWithoutPictures];

                    params.data.sales = result;
                    console.log('params.data.sales', params.data.sales);
                });
            }
        })
        .then(() => {
            if (params.data.page) {
                params.data.seo_title = params.data.page.seo_title;
                params.data.seo_description = params.data.page.seo_description;
                params.data.seo_keyword = params.data.page.seo_keyword;
            }

            if (action === 'create') {
                return dataProvider.create(resource, params);
            } else {
                return dataProvider.update(resource, params);
            }
        });
};

const uploadPictureDataProvider = {
    ...dataProvider,
    update: (resource, params) => uploadPicture(dataProvider, resource, params, 'update'),
    create: (resource, params) => uploadPicture(dataProvider, resource, params, 'create'),
};

export default uploadPictureDataProvider;