/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    AutocompleteArrayInput,
    required,
    ReferenceArrayInput,
    NumberInput,
    AutocompleteInput,
    minValue,
    maxValue,
    maxLength,
} from 'react-admin';
import { useForm } from 'react-final-form';

import Editor from "../components/Editor/Editor";
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import { itemsPerPage } from "../utils/variables";

const Title = ({ record }) => {
    return <span>Товар {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const ProductList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <ActivityButton
                {...props}
                datapath="products"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const { record } = rest;
    const [description, setDescription] = useState(record?.description);
    const [termsOfSale, setTermsOfSale] = useState(record?.terms_of_sale);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageSize, setImageSize] = useState(0);

    const handleImageDrop = (e) => onImageDrop(e, setImageWidth, setImageSize);

    const imageWidthValidator = () => validateImageWidth(
        imageWidth,
        [500],
        'Изображение не может быть шире 500 пикселей'
    );

    const imageSizeValidator = () => validateImageSize(
        imageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const imageValidator = [imageWidthValidator, imageSizeValidator];

    const form = useForm();

    useEffect(() => {
        form.change('description', description);
    }, [description]);

    useEffect(() => {
        form.change('terms_of_sale', termsOfSale);
    }, [termsOfSale]);

    const discountValidator = [minValue(0), maxValue(100)];

    return (
        <>
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

            <TextInput source="title" label="Название" validate={[required(), maxLength(100)]} fullWidth/>
            <Editor text={record?.description} setText={setDescription} label="Описание" />
            <Editor text={record?.terms_of_sale} setText={setTermsOfSale} label="Описание условий акции" />
            <NumberInput source="discount" label="Скидка" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} min="0" max="100" validate={discountValidator} />

            <ReferenceInput label="Магазин" reference="shops" source="shop_id" perPage={itemsPerPage} allowEmpty emptyText="— Очистить —" emptyValue={null} validate={[required()]} fullWidth>
                <AutocompleteInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={handleImageDrop} placeholder={<p>Перетащите сюда изображение <b>500*500px</b> весом до <b>500кб</b></p>} validate={type === 'create' ? [required(), imageValidator] : [imageValidator]}>
                <ImageField source="src" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="file.file_path" label="Изображение" />}

            <ReferenceArrayInput source="categories" reference="product-sale-categories" perPage={itemsPerPage} label="Категории" fullWidth validate={[required()]}>
                <AutocompleteArrayInput optionText="title" optionValue="id" />
            </ReferenceArrayInput>
        </>
    );
}

export const ProductEdit = props => {
    /*props means "name='123' fdsgdfg='dfgdfhgfgh'"*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};

export const ProductCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);