/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  SelectArrayInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from 'react-admin';

import { itemsPerPage } from '../utils/variables';

const FeedControlField = (props) => {
  const { isOnPage } = props; 

  const [bannerPages, setBannerPages] = useState([]);
  const [feedTypes, setFeedTypes] = useState([]);
  
  const authToken  = JSON.parse(localStorage.getItem('auth')).access_token;

  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/pages-for-banner?_limit=${itemsPerPage}`, {
          method: 'GET',
          headers: new Headers({
              'Authorization': `Bearer ${authToken}`,
          }),
      })
          .then(async (response) => {
              const pages = await response.json();
              setBannerPages(pages);
          })
          .catch((e) => console.log(e.message));
  }, []);

  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/feed-types?_limit=10&_offset=0`, {
          method: 'GET',
          headers: new Headers({
              'Authorization': `Bearer ${authToken}`,
          }),
      })
          .then(async (response) => {
              const feeds = await response.json();
              const sortedFeeds = feeds.sort((a, b) => a.id - b.id);
              setFeedTypes(sortedFeeds);
          })
          .catch((e) => console.log(e.message));
  }, []);

  return (
      isOnPage
        ? <SelectArrayInput label="Список страниц" source="pages" choices={bannerPages} optionText="title" optionValue="id" allowEmpty fullWidth />
        : (
          <>
            <ArrayInput source="feeds" label="Ленты новостей">
                <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
                    <SelectInput source="feed_type_id" label="Список лент" choices={feedTypes} optionText="title" optionValue="id" allowEmpty />
                    <NumberInput onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} label="Позиция" source="position" />
                </SimpleFormIterator>
            </ArrayInput>
            <br />
            <br />
          </>
        )
  );
};

export default FeedControlField;
