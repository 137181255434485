/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Button,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    DateField,
    required,
    ArrayInput,
    NumberInput,
    ArrayField,
    useDataProvider,
    maxLength,
    SimpleFormIterator,
    maxValue,
    minValue,
    FormDataConsumer,
    Labeled,
    useRefresh,
    useNotify,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import Editor from "../components/Editor/Editor";
import TagReferenceInput from '../components/TagReferenceInput';
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import ModalWindow from "../components/ModalWindow";
import ImageFeed from "../components/ImageFeed";
import '../components/componentStyles.css';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import urlValidator from "../utils/urlValidator";
import { itemsPerPage } from "../utils/variables";

const Title = ({ record }) => {
    return <span>Магазин {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const ShopList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />

            <ActivityButton
                {...props}
                datapath="shops"
                label="Активность"
            />

            <TextField source="categories_inline" label="Категории" sortable={false}/>
            <TextField source="locations_inline" label="Местоположение" sortable={false}/>
            <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />

            <EditButton label="" />
        </Datagrid>
    </List>
);

export const MySimpleForm = props => {
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [activeSliderId, setActiveSliderId] = useState(props?.record?.slider_id);
    const [sliderRecord, setSliderRecord] = useState(null);
    const [sliderImagesWidth, setSliderImagesWidth] = useState([]);
    const [shopImageWidth, setShopImageWidth] = useState(0);
    const [shopImageSize, setShopImageSize] = useState(0);
    const [sliderImagesSize, setSliderImagesSize] = useState([]);
    const [description, setDescription] = useState(props?.record?.description);

    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const form = useForm();

    useEffect(() => {
        form.change('description', description);
    }, [description, form]);

    const successMessage = 'Логотип успешно удален';
    const errorMessage = 'При удалении логотипа возникла ошибка';

    useEffect(() => {
        dataProvider.getOne('sliders', { id: activeSliderId })
            .then((response) => {
                setSliderRecord(response.data);
            })
            .catch((e) => console.log(e.message));
    }, [activeSliderId, dataProvider, form]);
    
    useEffect(() => {
        if (!modalIsOpened) {
            setSliderImagesWidth([]);
        }
    }, [modalIsOpened]);

    const handleSliderChange = (e) => {
        setActiveSliderId(e.target.value);
    }

    const handleModalEditLaunch = () => {
        if (!activeSliderId) {
            // TODO: show notification 'cannot edit inexistant slider'
            return;
        }
        setModalType('edit');
        setModalIsOpened(true);
    }

    const handleModalCreateLaunch = () => {
        setModalType('create');
        setModalIsOpened(true);
    }

    // const handleSalesClick = (e) => {
    //     e.stopPropagation();
    //     const elementId = e?.target?.parentNode?.getAttribute('id');
    //     const currentSalesOrder = elementId?.match(/\d/)[0] ;
    //     const currentSalesId = props?.record?.sales[currentSalesOrder]?.id;
    //     if (!currentSalesId) {
    //         // FIX: should be able to delete empty sales unit
    //         return;
    //     }
    //     e.target.disabled = true;
    //     dataProvider.delete('shop-sales', { id: currentSalesId })
    //         .then(() => {
    //             refresh();
    //         })
    //         .catch((e) => {
    //             e.target.disabled = false;
    //             console.error(e.message);
    //         });
    // }

    const onImageDelete = () => {
        dataProvider.update('shops', {
            data: { ...props?.record, file_id: null, file_ids: [] },
            id: props?.record?.id,
            previousData: props?.record,
        })
        .then((res) => {
            refresh();
            notify([successMessage], { type: 'success' });
        })
        .catch(() => {
            notify([errorMessage], { type: 'error' });
        });
    }

    const onShopImageDrop = (e) => onImageDrop(e, setShopImageWidth, setShopImageSize);
    const onSliderImagesDrop = (e) => onImageDrop(e, setSliderImagesWidth, setSliderImagesSize);

    const shopImageWidthValidator = () => validateImageWidth(
        shopImageWidth,
        [200],
        'Изображение не может быть шире 200 пикселей'
    );

    const shopImageSizeValidator = () => validateImageSize(
        shopImageSize,
        100000,
        'Изображение не может весить более 100кб'
    );

    const sliderImagesWidthValidator = () => validateImageWidth(
        sliderImagesWidth,
        [1488],
        'ШИзображение не может быть шире 1488 пикселей'
    );

    const sliderImagesSizeValidator = () => validateImageSize(
        sliderImagesSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const shopImageValidator = [shopImageWidthValidator, shopImageSizeValidator];
    const sliderImagesValidator = [sliderImagesWidthValidator, sliderImagesSizeValidator];

    return (
        <>
            <SimpleForm toolbar={null}>
                <TextField source="title" label="Название" />
                <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />
                <DateField source="create_at" label="Дата и время создания" showTime />
                <TextField source="guid" label="GUID" />
                <TextField source="categories_inline" label="Категории" />
                <TextField source="email" label="Email" />
                <TextField source="fb" />
                <TextField source="insta" />
                <TextField source="vk" />
                <TextField source="tg" />
                <TextField source="yt" label="Youtube" />
                <TextField source="website" label="Сайт" />

                <ArrayField source="phones" label="Телефоны" fullWidth>
                    <Datagrid>
                        <TextField source="phone" fullWidth label={false}/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="locations" label="Местоположение" fullWidth>
                    <Datagrid>
                        <TextField source="pavilion" fullWidth label="Павильон"/>
                        <TextField source="floor" fullWidth label="Этаж"/>
                        <TextField source="stand" fullWidth label="Место"/>
                        <TextField source="raw" fullWidth label="Стенд"/>
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
            <TextInput source="work_time" label="Часы работы" fullWidth validate={[maxLength(50)]} />

            <Editor text={props?.record?.description} setText={setDescription} label="Описание" />
            <TextInput source="short_text" label="Краткое описание" validate={maxLength(100)} fullWidth />

            <BooleanInput source="is_active" label="Активность" fullWidth/>
            <NumberInput source="show_in_search" label="Выводить в поиске" fullWidth min="0" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()}/>

            <ReferenceInput label="Слайдер" reference="sliders" perPage={itemsPerPage} source="slider_id" allowEmpty emptyText="— Очистить —" emptyValue={null} onChange={handleSliderChange} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <Button
                onClick={handleModalCreateLaunch}
                label="Новый слайдер"
            />
            <Button
                onClick={handleModalEditLaunch}
                label="Редактировать слайдер"
            />

            <ModalWindow modalIsOpened={modalIsOpened} setModalIsOpened={setModalIsOpened}>
                {
                    modalType === 'create' && (
                        <Create basePath="/sliders" resource="sliders" title=" -> Новый слайдер">
                            <SimpleForm redirect={false}>
                                <TextInput source="title" label="Название" validate={[required()]} fullWidth/>
                                <RichTextInput source="description" multiline label="Описание" fullWidth/>
                                <TextInput source="link" validate={urlValidator} label="Ссылка на подробную страницу" fullWidth/>
                                <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

                                <ReferenceInput reference="pages" source="page_id" label="Контентная страница" fullWidth allowEmpty>
                                    <SelectInput optionText="title" optionValue="id" />
                                </ReferenceInput>

                                <ImageInput multiple source="pictures" label="Загрузка изображений" accept="image/png,image/jpeg" onChange={onSliderImagesDrop} placeholder={<p>Перетащите сюда изображение шириной <b>1488px</b> весом до <b>500кб</b></p>} validate={[required(), sliderImagesValidator]} fullWidth>
                                    <ImageField source="src" label="Изображение" />
                                </ImageInput>
                            </SimpleForm>
                        </Create>
                    )
                }
                {
                    modalType === 'edit' && (
                        <Edit basePath={`/sliders/${activeSliderId}`} resource="sliders" id={activeSliderId} title=" -> Редактировать слайдер">
                            <SimpleForm record={sliderRecord} redirect={false} toolbar={<SimpleFormToolbar alwaysEnableSaveButton />}>
                                <TextInput source="title" label="Название" validate={[required()]} fullWidth/>
                                <RichTextInput source="description" multiline label="Описание" fullWidth/>
                                <TextInput source="link" validate={urlValidator} label="Ссылка на подробную страницу" fullWidth/>
                                <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

                                <ReferenceInput reference="pages" source="page_id" label="Контентная страница" fullWidth allowEmpty>
                                    <SelectInput optionText="title" optionValue="id" />
                                </ReferenceInput>

                                <ImageInput multiple source="pictures" label="Загрузка изображений" accept="image/png,image/jpeg" onChange={onSliderImagesDrop} placeholder={<p>Перетащите сюда изображение шириной <b>1488px</b> весом до <b>500кб</b></p>} validate={[sliderImagesValidator]} fullWidth>
                                    <ImageField source="src" label="Изображение" />
                                </ImageInput>

                                <ImageFeed record={sliderRecord} />
                            </SimpleForm>
                        </Edit>
                    )
                }
            </ModalWindow>

            <ImageInput source="picture" label="Логотип" accept="image/png,image/jpeg" onChange={onShopImageDrop} placeholder={<p>Перетащите сюда квадратный логотип шириной <b>200px</b> весом до <b>100кб</b></p>} fullWidth validate={shopImageValidator}>
                <ImageField source="src" label="Логотип" />
            </ImageInput>

            <ImageField source="file.file_path" label="Логотип" fullWidth/>

            {props?.record?.file_id && (
                <button
                    type="button"
                    className="remove-button delete-logo-button MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-6 ra-delete-button RaDeleteWithConfirmButton-deleteButton-148 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={onImageDelete}
                >
                    <RemoveCircleOutlineIcon />
                    <span>Удалить логотип</span>
                </button>
            )}

            <TagReferenceInput
                label="Теги"
                reference="tags"
                source="tags"
                fullWidth
            />

            <ArrayInput source="sales" label="Акции">
                <SimpleFormIterator disableReordering allowEmpty TransitionProps={{ enter: false, exit: false }}>
                    <TextInput source="title" label="Название акции" validate={[maxLength(50, 'Название не может быть длиннее 50 символов')]} fullWidth />
                    <TextInput source="description" label="Описание акции" fullWidth />
                    <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" maxSize={200000} placeholder={<p>Перетащите сюда изображение шириной <b>720px</b></p>} fullWidth>
                        <ImageField source="src" label="Изображение" fullWidth />
                    </ImageInput>
                    { props.type === 'update' && (
                        <FormDataConsumer>
                            {
                                ({ getSource }) => <Labeled label="Загруженное изображение"><ImageField source={getSource("file.file_path")} /></Labeled>
                            }
                        </FormDataConsumer>
                    )}
                    <NumberInput source="discount" label="Скидка %" validate={[minValue(1, 'Значение скидки не может быть меньше 1'), maxValue(99, 'Значение скидки не может превышать 99%')]} onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} fullWidth />
                    <BooleanInput source="is_active" label="Активность" initialValue={true} fullWidth />
                    {/* <button
                        type="button"
                        className="remove-button delete-logo-button MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-6 ra-delete-button RaDeleteWithConfirmButton-deleteButton-148 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                        onClick={handleSalesClick}
                    >
                        <RemoveCircleOutlineIcon />
                        <span>Удалить акцию</span>
                    </button> */}
                    <br />
                    <br />
                </SimpleFormIterator>
            </ArrayInput>
            <br />
            <br />

            <ArrayInput source="brands_ordered" label="Бренды">
                <SimpleFormIterator disableClear disableAdd disableRemove disableReordering TransitionProps={{ enter: false, exit: false }}>
                    <FormDataConsumer>
                        {
                            ({ getSource }) => <Labeled label="Название бренда"><TextField source={getSource("name")} /></Labeled>
                        }
                    </FormDataConsumer>
                    <NumberInput source="order" label="Порядок вывода" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} />
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput source="page.seo_title" label="SEO Title" fullWidth/>
            <TextInput source="page.seo_description" label="SEO Description" fullWidth/>
            <TextInput source="page.seo_keyword" label="SEO Keywords" fullWidth/>
        </>
    )
};

export const ShopEdit = props => {
    /*props means "name='123' fdsgdfg='dfgdfhgfgh'"*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar candelete="false" />}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};