import * as React from "react";
import { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    NumberInput,
    DateField,
    useListContext,
} from 'react-admin';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import '../components/componentStyles.css';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';

const Title = ({ record }) => {
    return <span>Категория {record ? `"${record.title}"` : ''}</span>;
};

const NodeRubricsFilter = () => {
    const [filter, setFilter] = useState(false);
    const context = useListContext();

    let wasClicked = false;

    const handleClick = () => {
        wasClicked = true;
        if (!filter && wasClicked) {
            context.setFilters({ parent_id: 0 });
        } else {
            context.setFilters({}, []);
        }
        setFilter((prev) => !prev);
    }

    return (
        <div className="node-rubrics-filter">
            {
                filter
                    ? <CheckBoxIcon className="node-rubrics-filter__checkbox" onClick={handleClick} />
                    : <CheckBoxOutlineBlankIcon className="node-rubrics-filter__checkbox" onClick={handleClick} />
            }
            <span>Узловые</span>
        </div>
    );
}

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    <NodeRubricsFilter alwaysOn />
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const CategoryList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />

            <ActivityButton
                {...props}
                datapath="categories"
                label="Активность"
            />

            <TextField source="sort" label="Сортировка" />
            <ImageField source="file.file_path" label="Изображение" sortable={false}/>
            <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />

            <EditButton label="" />
        </Datagrid>
    </List>
);

export const CategoryEdit = props => {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageSize, setImageSize] = useState(0);

    const handleImageDrop = (e) => onImageDrop(e, setImageWidth, setImageSize);

    const imageWidthValidator = () => validateImageWidth(
        imageWidth,
        [464],
        'Изображение не может быть шире 464 пикселей'
    );

    const imageSizeValidator = () => validateImageSize(
        imageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const imageValidator = [imageWidthValidator, imageSizeValidator];

    return (
        <Edit title={<Title />} {...props}>
            <SimpleForm toolbar={<SimpleFormToolbar candelete="false"/>}>
                <TextField source="title" label="Название" />
                <TextField source="parent.title" label="Родительская категория" />
                <DateField source="parsed_at" label="Дата и время последнего парсинга" showTime />
                <DateField source="create_at" label="Дата и время создания" showTime />
                <TextField source="guid" label="GUID" />

                <NumberInput source="sort" label="Сортировка" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} fullWidth min="0"/>
                <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={handleImageDrop} placeholder={<p>Перетащите сюда изображение <b>464*624px</b> весом до <b>500кб</b></p>} validate={imageValidator} fullWidth>
                    <ImageField source="src" label="Изображение" />
                </ImageInput>

                <ImageField source="file.file_path" label="Изображение" />

                <BooleanInput source="is_active" label="Активность" fullWidth/>
            </SimpleForm>
        </Edit>
    )
}
