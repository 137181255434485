/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required,
    DateTimeInput,
} from 'react-admin';
import { useForm } from 'react-final-form';

import Editor from "../components/Editor/Editor";
import ActivityButton from '../components/ActivityButton';
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';

const Title = ({ record }) => {
    return <span>Маркетинговая акция</span>;
};

const CustomTitleField = (props) => {
    const title = props?.record?.second_slide_title;
    const stripHTML = (text) => text.replace(/<(.|\n)*?>/g, '');
    return <span>{stripHTML(title)}</span>
}


export const MarketingCampaignList = props => (
    <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <CustomTitleField source="second_slide_title" label="Название" />
            <ActivityButton
                {...props}
                datapath="marketing-campaigns"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const { record } = rest;
    const [desktopImageWidth, setDesktopImageWidth] = useState(0);
    const [desktopImageSize, setDesktopImageSize] = useState(0);
    const [mobileImageWidth, setMobileImageWidth] = useState(0);
    const [mobileImageSize, setMobileImageSize] = useState(0);

    const [stubText, setStubText] = useState(record?.stub_text);
    const [firstSlideTitle, setFirstSlideTitle] = useState(record?.first_slide_title);
    const [firstSlideDescription, setFirstSlideDescription] = useState(record?.first_slide_description);
    const [secondSlideTitle, setSecondSlideTitle] = useState(record?.second_slide_title);
    const [secondSlideShortDescription, setSecondSlideShortDescription] = useState(record?.second_slide_short_desciption);
    const [secondSlideDescription, setSecondSlideDescription] = useState(record?.second_slide_description);
    const [firstAdvantageTitle, setFirstAdvantageTitle] = useState(record?.first_advantage_title);
    const [firstAdvantageDescription, setFirstAdvantageDescription] = useState(record?.first_advantage_desciption);
    const [secondAdvantageTitle, setSecondAdvantageTitle] = useState(record?.second_advantage_title);
    const [secondAdvantageDescription, setSecondAdvantageDescription] = useState(record?.second_advantage_description);
    const [thirdAdvantageTitle, setThirdAdvantageTitle] = useState(record?.third_advantage_title);
    const [thirdAdvantageDescription, setThirdAdvantageDescription] = useState(record?.third_advantage_description);

    const form = useForm();

    useEffect(() => {
        form.change('stub_text', stubText);
    }, [stubText]);

    useEffect(() => {
        form.change('first_slide_title', firstSlideTitle);
    }, [firstSlideTitle]);

    useEffect(() => {
        form.change('first_slide_description', firstSlideDescription);
    }, [firstSlideDescription]);

    useEffect(() => {
        form.change('second_slide_title', secondSlideTitle);
    }, [secondSlideTitle]);

    useEffect(() => {
        form.change('second_slide_short_description', secondSlideShortDescription);
    }, [secondSlideShortDescription]);

    useEffect(() => {
        form.change('second_slide_description', secondSlideDescription);
    }, [secondSlideDescription]);

    useEffect(() => {
        form.change('first_advantage_title', firstAdvantageTitle);
    }, [firstAdvantageTitle]);

    useEffect(() => {
        form.change('first_advantage_description', firstAdvantageDescription);
    }, [firstAdvantageDescription]);

    useEffect(() => {
        form.change('second_advantage_title', secondAdvantageTitle);
    }, [secondAdvantageTitle]);

    useEffect(() => {
        form.change('second_advantage_description', secondAdvantageDescription);
    }, [secondAdvantageDescription]);

    useEffect(() => {
        form.change('third_advantage_title', thirdAdvantageTitle);
    }, [thirdAdvantageTitle]);

    useEffect(() => {
        form.change('third_advantage_description', thirdAdvantageDescription);
    }, [thirdAdvantageDescription]);


    const isValidDate = (value) => !isNaN(Date.parse(value));

    const validateStartDate = (value, allValues) => {
        if (!isValidDate(value)) {
            return 'Поле не может быть пустым';
        }
        if (new Date(value) >= new Date(allValues.date_end)) {
            return 'Дата начала не может быть больше даты окончания';
        }
        return undefined;
    }

    const validateEndDate = (value, allValues) => {
        if (!isValidDate(value)) {
            return 'Поле не может быть пустым';
        }
        if (new Date(value) <= new Date(allValues.date_start)) {
            return 'Дата окончания не может быть меньше даты начала';
        }
        return undefined;
    }

    const startDateValidation = [required(), validateStartDate];
    const endDateValidation = [required(), validateEndDate];

    const onDesktopImageDrop = (e) => onImageDrop(e, setDesktopImageWidth, setDesktopImageSize);
    const onMobileImageDrop = (e) => onImageDrop(e, setMobileImageWidth, setMobileImageSize);

    const desktopImageWidthValidator = () => validateImageWidth(
        desktopImageWidth,
        [1920],
        'Изображение не может быть шире 1920 пикселей'
    );

    const desktopImageSizeValidator = () => validateImageSize(
        desktopImageSize,
        500000,
        'Изображение не может весить более 500кб'
    )

    const mobileImageWidthValidator = () => validateImageWidth(
        mobileImageWidth,
        [768, 320],
        'Изображение не может быть шире 768 пикселей'
    );

    const mobileImageSizeValidator = () => validateImageSize(
        mobileImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const desktopImageValidator = [desktopImageWidthValidator, desktopImageSizeValidator];
    const mobileImageValidator = [mobileImageWidthValidator, mobileImageSizeValidator];

    //return in () jsx
    return (
        <>
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>
            <Editor text={rest?.record?.stub_text} setText={setStubText} label="Текст заглушки" />

            <BooleanInput source="show_first_slide" label="Отображать 1 экран" fullWidth />

            <Editor text={record?.first_slide_title} setText={setFirstSlideTitle} label="Заголовок 1" />           
            <Editor text={record?.first_slide_description} setText={setFirstSlideDescription} label="Анонс 1" />         

            <Editor text={record?.second_slide_title} setText={setSecondSlideTitle} label="Заголовок 2" />    
            <Editor text={record?.second_slide_short_desciption} setText={setSecondSlideShortDescription} label="Анонс 2" />
            <Editor text={record?.second_slide_description} setText={setSecondSlideDescription} label="Описание" />

            <p><DateTimeInput source="date_start" label="Дата начала"  validate={startDateValidation} /></p>
            <p><DateTimeInput source="date_end" label="Дата окончания" validate={endDateValidation} /></p>

            <Editor text={record?.first_advantage_title} setText={setFirstAdvantageTitle} label="Преимущество 1 заголовок" />    
            <Editor text={record?.first_advantage_desciption} setText={setFirstAdvantageDescription} label="Преимущество 1 описание" />

            <Editor text={record?.second_advantage_title} setText={setSecondAdvantageTitle} label="Преимущество 2 заголовок" />  
            <Editor text={record?.second_advantage_description} setText={setSecondAdvantageDescription} label="Преимущество 2 описание" />

            <Editor text={record?.third_advantage_title} setText={setThirdAdvantageTitle} label="Преимущество 3 заголовок" />  
            <Editor text={record?.third_advantage_description} setText={setThirdAdvantageDescription} label="Преимущество 3 описание" />

            <BooleanInput source="dark_theme" label="Темный фон" fullWidth />

            <BooleanInput source="show_event_slide" label="Выводить мероприятия" fullWidth />

            <ImageInput source="picture_desk" label="Загрузка изображения десктоп" accept="image/png,image/jpeg" onChange={onDesktopImageDrop} placeholder={<p>Перетащите сюда изображение <b>1920*1080px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), desktopImageValidator]) || desktopImageValidator}>
                <ImageField source="src_desk" label="Изображение десктоп" />
            </ImageInput>

            {type === 'update' && <ImageField source="first_slide_desktop_file.file_path" label="Изображение десктоп" />}

            <ImageInput source="picture_mob" label="Загрузка изображения мобайл" accept="image/png,image/jpeg" onChange={onMobileImageDrop} placeholder={<p>Перетащите сюда изображение <b>768*1024px</b> или <b>320*568px</b> весом до <b>500кб</b></p>}validate={(type === 'create' && [required(), mobileImageValidator]) || mobileImageValidator}>
                <ImageField source="src_mob" label="Изображение мобайл" />
            </ImageInput>

            {type === 'update' && <ImageField source="first_slide_mobile_file.file_path" label="Изображение мобайл" />}

            <TextInput source="seo_title" label="SEO Title" fullWidth/>
            <TextInput source="seo_description" label="SEO Description" fullWidth/>
            <TextInput source="seo_keyword" label="SEO Keywords" fullWidth/>
        </>
    );
}


export const MarketingCampaignEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar {...props} candelete="false"/>}>
            <MySimpleForm type='update' />
        </SimpleForm>
    </Edit>
);
