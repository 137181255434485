/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    DateField,
    required,
    ReferenceArrayInput,
    DateTimeInput,
    maxLength,
    SelectArrayInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';

import Editor from "../components/Editor/Editor";
import TagReferenceInput from '../components/TagReferenceInput';
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';

import '../App.css';
import { itemsPerPage } from "../utils/variables";

const Title = ({ record }) => {
    return <span>Материал {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    <SelectInput
        source="material_type_id"
        label="Тип материала"
        choices={[
            { id: 0, name: '— Не выбрано —'},
            { id: 1, name: 'Мероприятия' },
            { id: 2, name: 'Статьи' },
            { id: 3, name: 'Новости'}
        ]}
        alwaysOn
        allowEmpty={false}
    />
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const MaterialList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <ActivityButton
                {...props}
                datapath="materials"
                label="Активность"
            />
            <TextField source="material_type.title" label="Тип материала" />
            <TextField source="news_type.title" label="Тип новости" />
            <TextField source="article_type.title" label="Тип статьи" />
            <TextField source="article_room_type.title" label="Тип помещения для статей" />
            <TextField source="event_type.title" label="Тип мероприятия" />
            <DateField source="event_date" label="Дата и время мероприятия" showTime />
            <DateField source="event_date_end" label="Дата и время окончания мероприятия" showTime />
            <DateField source="publish_at" label="Дата публикации" showTime />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const { record } = rest;
    const [bigImageWidth, setBigImageWidth] = useState(0);
    const [bigImageSize, setBigImageSize] = useState(0);
    const [mainImageWidth, setMainImageWidth] = useState(0);
    const [smallImageWidth, setSmallImageWidth] = useState(0);
    const [smallImageSize, setSmallImageSize] = useState(0);
    const [mainImageSize, setMainImageSize] = useState(0);
    const [subTitle, setSubTitle] = useState(record?.sub_title);
    const [description, setDescription] = useState(record?.description);

    const form = useForm();

    useEffect(() => {
        form?.change('description', description);
    }, [description]);

    useEffect(() => {
        form.change('sub_title', subTitle);
    }, [subTitle]);

    const materialIds = [1, 2, 3];
    const materialsById = {
        1: 'Мероприятия',
        2: 'Статьи',
        3: 'Новости',
    };

    const newsById = {
        1: 'Акции и скидки',
        2: 'Новости EXPO',
    };

    const [materialType, setMaterialType] = useState({
        [materialsById[1]]: false,
        [materialsById[2]]: false,
        [materialsById[3]]: false,
    });

    const [newsType, setNewsType] = useState('');

    useEffect(() => {
        if (record) {
            const { material_type_id, news_type_id } = record;

            setMaterialType((prev) => (
                { ...prev, [materialsById[material_type_id]]: true }
            ));

            setNewsType(newsById[news_type_id]);
        }
    }, [record]);

    const handleMaterialTypeChange = (val) => {
        materialIds.forEach((el) => setMaterialType((prev) => {
            if (el === val) {
                return { ...prev, [materialsById[el]]: true };
            }
            return { ...prev, [materialsById[el]]: false };
        }));
    }

    const handleNewsTypeChange = (val) => {
        setNewsType(newsById[val]);
    }

    const newsValidation = [materialType[materialsById[3]] && required()];
    const articleValidation = [materialType[materialsById[2]] && required()]
    const eventValidation = [materialType[materialsById[1]] && required()]

    const onBigImageDrop = (e) => onImageDrop(e, setBigImageWidth, setBigImageSize);
    const onSmallImageDrop = (e) => onImageDrop(e, setSmallImageWidth, setSmallImageSize);
    const onMainImageDrop = (e) => onImageDrop(e, setMainImageWidth, setMainImageSize);

    const bigImageWidthValidator = () => validateImageWidth(
        bigImageWidth,
        [720],
        'Изображение не может быть шире 720 пикселей'
    );

    const bigImageSizeValidator = () => validateImageSize(
        bigImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const smallImageWidthValidator = () => validateImageWidth(
        smallImageWidth,
        [336],
        'Изображение не может быть шире 336 пикселей'
    );

    const smallImageSizeValidator = () => validateImageSize(
        smallImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const mainImageWidthValidator = () => validateImageWidth(
        mainImageWidth,
        [976],
        'Изображение не может быть шире 976 пикселей'
    );

    const mainImageSizeValidator = () => validateImageSize(
        mainImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );


    const imageAvailabilityValidator = (field) => () => {
        if (type === 'update') {
            return undefined;
        }
        if (!field) {
            return 'Обязательно для заполнения';
        }
        return undefined;
    }

    const bigImageValidator = [bigImageWidthValidator, bigImageSizeValidator, imageAvailabilityValidator(record?.square_file_id)];
    const smallImageValidator = [smallImageWidthValidator, smallImageSizeValidator, imageAvailabilityValidator(record?.file_id)]
    const mainImageValidator = [mainImageWidthValidator, mainImageSizeValidator, imageAvailabilityValidator(record?.main_file_id)];

    const isNewsTypeArticle = materialType[materialsById[3]] && newsType === newsById[1];

    const isValidDate = (value) => !isNaN(Date.parse(value));

    const validateDateNotEmpty = (value, allValues) => {
        if (!isValidDate(value)) {
            return 'Поле не может быть пустым';
        }
        return undefined;
    }
    //return in () jsx

    return (
        <>
            <TextInput source="title" label="Название" validate={[required(), maxLength(255)]} fullWidth/>
            <Editor text={record?.sub_title} setText={setSubTitle} label="Анонс" />
            <Editor text={record?.description} setText={setDescription} label="Описание" />

            <DateField source="create_at" label="Дата и время создания" showTime />

            <ReferenceInput label="Тип материала" reference="material-types" source="material_type_id" onChange={(e) => handleMaterialTypeChange(e.target.value)} fullWidth validate={[required()]}>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>
            {materialType[materialsById[3]] && (<ReferenceInput label="Тип новости" reference="news-types" onChange={(e) => handleNewsTypeChange(e.target.value)} source="news_type_id" validate={newsValidation} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>)}
            {materialType[materialsById[2]] && (<ReferenceInput label="Тип статьи" reference="article-types" source="article_type_id" validate={articleValidation} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>)}
            {materialType[materialsById[2]] && (<ReferenceInput label="Тип помещения для статей" reference="article-room-types" source="article_room_type_id" validate={articleValidation} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>)}
            {materialType[materialsById[1]] && (<ReferenceInput label="Тип мероприятия" reference="event-types" source="event_type_id" validate={eventValidation} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>)}

            {materialType[materialsById[1]] && <p><DateTimeInput source="event_date" label="Дата и время мероприятия" validate={[eventValidation && validateDateNotEmpty]} /></p>}
            {materialType[materialsById[1]] && <p><DateTimeInput source="event_date_end" label="Дата и время окончания мероприятия" /></p>}
            <p><DateTimeInput source="publish_at" label="Дата публикации" validate={[validateDateNotEmpty && required()]} /></p>
            {isNewsTypeArticle && <p><DateTimeInput source="date_start" label="Дата и время начала" validate={[newsValidation && validateDateNotEmpty]} /></p>}
            {isNewsTypeArticle && <p><DateTimeInput source="date_end" label="Дата и время окончания" validate={[newsValidation && validateDateNotEmpty]} /></p>}

            {(materialType[materialsById[2]] || materialType[materialsById[3]]) && <BooleanInput source="is_zen" label="Публиковать в Яндекс Дзен" fullWidth/>}

            <ImageInput source="picture_main" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onMainImageDrop} placeholder={<p>Перетащите сюда главное изображение шириной до <b>976px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), mainImageValidator]) || mainImageValidator}>
                <ImageField source="src_main" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="main_file.file_path" label="Изображение" />}

            <ImageInput source="picture_square" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onBigImageDrop} placeholder={<p>Перетащите сюда квадратное изображение шириной <b>720px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), bigImageValidator]) || bigImageValidator}>
                <ImageField source="src_square" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="square_file.file_path" label="Изображение" />}

            <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onSmallImageDrop} placeholder={<p>Перетащите сюда вертикальное изображение шириной <b>336px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), smallImageValidator]) || smallImageValidator}>
                <ImageField source="src" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="file.file_path" label="Изображение" />}

            {materialType[materialsById[1]] && <ReferenceArrayInput perPage={itemsPerPage} source="speakers" reference="speakers" label="Спикеры" fullWidth>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>}

            {materialType[materialsById[1]] && <BooleanInput source="is_pay" label="Платное мероприятие" fullWidth/>}
            {materialType[materialsById[1]] && <BooleanInput source="is_registration" label="Требуется регистрация" fullWidth/>}
            {!materialType[materialsById[3]] && <BooleanInput source="is_pro" label="Для дизайнеров" fullWidth/>}
            <BooleanInput source="show_on_main_page" label="Показывать на главной" fullWidth/>
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>
            <BooleanInput source="show_in_menu" label="Выводить в меню" fullWidth />

            <TagReferenceInput
                label="Теги"
                reference="tags"
                source="tags"
                fullWidth
            />

            <TextInput source="page.seo_title" label="SEO Title" fullWidth/>
            <TextInput source="page.seo_description" label="SEO Description" fullWidth/>
            <TextInput source="page.seo_keyword" label="SEO Keywords" fullWidth/>
        </>
    );
}

export const MaterialEdit = withStyles({ card: { overflow: 'initial' } })(props => {
    /*name='123' fdsgdfg='dfgdfhgfgh'*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update' />
        </SimpleForm>
    </Edit>
});

export const MaterialCreate = withStyles({ card: { overflow: 'initial' } })(props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
));