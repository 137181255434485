const urlValidator = (value) => {
  if (!value) {
    return undefined;
  }

  const errorMessage = 'Ссылка должна начинаться с https:// или http://'
  const schema = /^(http|https):\/\//;

  if (!value.match(schema)) {
      return errorMessage;
  }

  return undefined;
};

export default urlValidator;