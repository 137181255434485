import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
  name: 'gallery',

  group: 'block',

  content: 'block*',

  addAttributes() {
    return {
      class: {
        default: "image-gallery"
      },
      imageData: {
        default: null,
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'image-gallery',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { imageData, ...rest } = HTMLAttributes;
    // const images = imageData.map((img) => (['img', mergeAttributes(img)]));
    return ['image-gallery', mergeAttributes(rest), 0];
  },

  addCommands() {
    return {
      setGallery: (options) => ({ commands }) => {
        return commands.insertContent([
          {
            type: this.name,
            attrs: options,
            content: [
              {
                type: 'image',
                attrs: {
                  src: 1,
                },
              },
              {
                type: 'image',
                attrs: {
                  src: 2,
                },
              },
              {
                type: 'image',
                attrs: {
                  src: 3,
                },
              },
            ],
          }
        ]);
      },
    }
  }
});
