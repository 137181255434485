/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required,
    maxLength,
} from 'react-admin';
import { useForm } from 'react-final-form';
import FeedControlField from '../components/FeedControlField';

import Editor from '../components/Editor/Editor';
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';

const stripHtml = (text) => text.replace(/<(.|\n)*?>/g, '');

const Title = ({ record }) => {
    return <span>Баннер {record ? `"${stripHtml(record?.title)}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

const CustomTitle = (props) => {
    const title = stripHtml(props?.record?.title);
    return <span>{title}</span>;
}

export const BannerList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <CustomTitle label="Название" />

            <ActivityButton
                {...props}
                datapath="banners"
                label="Активность"
            />

            <TextField source="pages_inline" label="Страница размещения" sortable={false}/>
            <TextField source="feeds_inline" label="Позиция" sortable={false}/>

            <EditButton label="" />
        </Datagrid>
    </List>
);

export const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const { record } = rest; 
    const { on_page } = record;
    const [isOnPage, setIsOnPage] = useState(on_page);
    const [desktopImageWidth, setDesktopImageWidth] = useState(0);
    const [desktopImageSize, setDesktopImageSize] = useState(0);
    const [mobileImageWidth, setMobileImageWidth] = useState(0);
    const [mobileImageSize, setMobileImageSize] = useState(0);
    const [title, setTitle] = useState(record?.title);
    const [description, setDescription] = useState(record?.description);

    const form = useForm();

    useEffect(() => {
        form?.change('title', title);
    }, [title]);

    useEffect(() => {
        form?.change('description', description);
    }, [description]);

    const handleMenuChoice = () => {
        setIsOnPage((prev) => !prev)
    };

    const onDesktopImageDrop = (e) => onImageDrop(e, setDesktopImageWidth, setDesktopImageSize);
    const onMobileImageDrop = (e) => onImageDrop(e, setMobileImageWidth, setMobileImageSize);

    const desktopImageWidthValidator = () => validateImageWidth(
        desktopImageWidth,
        [1104, 1488],
        'Изображение не может быть шире 1488 пикселей',
    );

    const desktopImageSizeValidator = () => validateImageSize(
        desktopImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const mobileImageWidthValidator = () => validateImageWidth(
        mobileImageWidth,
        [336, 720],
        'Изображение не может быть шире 720 пикселей',
    );

    const mobileImageSizeValidator = () => validateImageSize(
        mobileImageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const desktopImageValidator = [desktopImageWidthValidator, desktopImageSizeValidator];
    const mobileImageValidator = [mobileImageWidthValidator, mobileImageSizeValidator];

    return (
        <>
            <Editor text={record?.title} setText={setTitle} label="Название" />
            <BooleanInput source="is_title" defaultValue={false} label="Не показывать название" fullWidth />
            <Editor text={record?.description} setText={setDescription} label="Описание" />

            <TextInput source="button_link" validate={[maxLength(255)]} fullWidth label="Ссылка кнопки 1"/>
            <TextInput source="button_title" validate={[maxLength(255)]} fullWidth label="Название кнопки 1"/>
            <TextInput source="second_button_link" validate={[maxLength(255)]} fullWidth label="Ссылка кнопки 2"/>
            <TextInput source="second_button_title" validate={[maxLength(255)]} fullWidth label="Название кнопки 2"/>

            <BooleanInput source="on_page" onChange={handleMenuChoice} label="Выводить на контентной странице" fullWidth/>
            <FeedControlField {...rest} isOnPage={isOnPage} />

            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

            <ImageInput source="picture_desk" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onDesktopImageDrop} placeholder={<p>Перетащите сюда десктоп изображение шириной <b>1104px</b> или <b>1488px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required() && desktopImageValidator]) || desktopImageValidator}>
                <ImageField source="src_desk" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="desktop_file.file_path" label="Десктоп изображение" />}

            <ImageInput source="picture_mob" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onMobileImageDrop} placeholder={<p>Перетащите сюда мобильное изображение шириной <b>336px</b> или <b>720px</b> весом до <b>500кб</b></p>} validate={(type === 'create' && [required() && mobileImageValidator]) || mobileImageValidator}>
                <ImageField source="src_mob" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageField source="mobile_file.file_path" label="Мобильное изображение" />}
        </>
    );
}

export const BannerEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update' {...props} />
        </SimpleForm>
    </Edit>
);

export const BannerCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);