/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required,
} from 'react-admin';

import ActivityButton from "../components/ActivityButton";
import ImageFeed from "../components/ImageFeed";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import { itemsPerPage } from '../utils/variables';

const Title = ({ record }) => {
    return <span>Слайдер {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];


export const SliderList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <ActivityButton
                {...props}
                datapath="sliders"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const [imagesWidth, setImagesWidth] = useState([]);
    const [imagesSize, setImagesSize] = useState([]);

    const onImagesDrop = (e) => onImageDrop(e, setImagesWidth, setImagesSize);

    const imagesWidthValidator = () => validateImageWidth(
        imagesWidth,
        [1920, 1488],
        'Ширина изображений должна быть равна 1920 или 1488 пикселей'
    );

    const imagesSizeValidator = () => validateImageSize(
        imagesSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const imagesValidator = [imagesWidthValidator, imagesSizeValidator];

    //return in () jsx
    return (
        <>
            <TextInput source="title" label="Название" validate={[required()]} fullWidth/>
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

            <ReferenceInput reference="pages" source="page_id" label="Контентная страница" perPage={itemsPerPage} allowEmpty emptyText="— Очистить —" emptyValue={null} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <ImageInput multiple source="pictures" label="Загрузка изображений" accept="image/png,image/jpeg" onChange={onImagesDrop} placeholder={<p>Перетащите сюда изображение шириной <b>1920px</b> для главной и <b>1488px</b> для остальных весом до <b>500кб</b></p>} validate={(type === 'create' && [required(), imagesValidator]) || imagesValidator}>
                <ImageField source="src" label="Изображение" />
            </ImageInput>

            {type === 'update' && <ImageFeed record={rest.record} />}
        </>
    );
}


export const SliderEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update' />
        </SimpleForm>
    </Edit>
);

export const SliderCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);