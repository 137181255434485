import * as React from "react";
import { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
} from 'react-admin';
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';

const Title = ({ record }) => {
    return <span>Спикер {record ? `"${record.name}"` : ''}</span>;
};

const Filters = [
    <TextInput source="name" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const SpeakerList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="file.file_path" label="Фотография" />
            <TextField source="name" label="ФИО" />
            <TextField source="position" label="Должность" />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
}) => {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageSize, setImageSize] = useState(0);

    const onSpeakerImageDrop = (e) => onImageDrop(e, setImageWidth, setImageSize);

    const speakerImageWidthValidator = () => validateImageWidth(
      imageWidth,
      [336],
      'Ширина изображеня должна быть равна 336 пикселям'
  );
  
  const speakerImageSizeValidator = () => validateImageSize(
      imageSize,
      200000,
      'Изображение не может весить более 200кб'
  );

  const speakerImageValidator = [speakerImageWidthValidator, speakerImageSizeValidator, type === 'create' && required()];
    //return in () jsx
    return (
        <>
          <ImageInput source="picture" label="Загрузка изображения" accept="image/png,image/jpeg" onChange={onSpeakerImageDrop} placeholder={<p>Перетащите сюда изображение спикера шириной до <b>336px</b> весом до <b>200кб</b></p>} validate={speakerImageValidator} fullWidth>
            <ImageField source="file.file_path" label="Изображение" />
          </ImageInput>

          {type === 'update' && <ImageField source="file.file_path" label="Загруженное изображение" />}

          <TextInput source="name" label="ФИО" validate={[required()]} fullWidth />
          <TextInput source="position" label="Должность" validate={[required()]} fullWidth />
          <TextInput source="description" label="Описание работы" validate={[required()]} fullWidth />
        </>
    );
}

export const SpeakerEdit = props => {
    /*props means "name='123' fdsgdfg='dfgdfhgfgh'"*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};

export const SpeakerCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);