import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        type: 'light',
        secondary: {
            main: '#5181b8',
        },
    },
    overrides: {
        RaLayout: {
            root: {
                backgroundColor: '#edeef0',
            },
            content: {
                backgroundColor: '#edeef0',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(63, 81, 181, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiFormGroup: {
            root: {
                width: 'max-content',
            },
        },
        MuiFormLabel: {
            root: {
                whiteSpace: 'nowrap',
            },
        },
        RaTreeMenu: {
            open: {
                width: 300
            },
        },
        MuiButtonBase: {
            root: {
                color: '#000000'
            }
        },
        RaMenuItemLink: {
            active: {
                borderLeft: '3px solid #5B81B4',
                backgroundColor: '#fff',
            },
            root: {
                color: '#000000'
            }
        },
    },
    sidebar: {
        width: 250, // The default value is 240
        closedWidth: 70, // The default value is 55
    },
});

export default theme;