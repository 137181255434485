/* eslint-disable import/no-anonymous-default-export */

import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const imageUrl = `${process.env.REACT_APP_IMG_URL}`;
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token  = JSON.parse(localStorage.getItem('auth')).access_token;
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
};

const materialTypes = {
    1: 'Мероприятия',
    2: 'Статьи',
    3: 'Новости',
};

const adjustTime = (date) => {
    if (typeof date === 'string') {
        return date;
    }
    const hours = date.getHours();
    const timeZoneSurplus = new Date().getTimezoneOffset() / 60;
    // const timeZoneSurplus = 3; // Moscow time zone UTC+3
    const newDate = date.setHours(hours - timeZoneSurplus);
    const result = new Date(newDate);
    return result;
}

const ignoreTimezone = (time) => {
    const date = new Date(time);
    const timeZoneOffset = 3 * 60 * 60000;
    const userOffset = date.getTimezoneOffset() * 60000;
    const currentTime = new Date(date.getTime() + timeZoneOffset + userOffset);
    return new Date(currentTime);
};

export default {
    //GET http://my.api.url/posts?_sort=title&_order=ASC&_offset=0&_limit=24
    getList: (resource, params) => {
        console.log(params);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const filter = params.filter;
        const { parent_id, material_type_id, ...rest } = filter;
        params.filter = rest;

        const filterByParentId = parent_id === 0 ? `&parent_id=${parent_id}` : '';
        const filterByMaterialTypeId = (material_type_id && material_type_id !== 0) ? `&material_type_id=${material_type_id}` : '';

        const query = {
            //...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _offset: (page - 1) * perPage,
            _limit: perPage,
            _filter: JSON.stringify(params.filter),
        };
        
        /*console.log('resource', resource);
        console.log('query', query);*/
        
        const url = `${apiUrl}/${resource}?${stringify(query)}${filterByParentId}${filterByMaterialTypeId}`;

        return httpClient(url).then(({ headers, json }) => {
            json.forEach((item) => {
                let path = item?.file?.file_path;
                if (path) {
                    item.file.file_path = `${imageUrl}/${path}`;
                }
            });
            return {
                data: json,
                total: parseInt(headers.get('X-Total-Count'), 10),
            }
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            /*let restaurants = [];
            json?.restaurants?.forEach((item) => {
                restaurants.push(item?.id);
            });
            let tags = [];
            json?.tags?.forEach((item) => {
                tags.push(item?.id);
            });*/

            let path = json?.file?.file_path;
            if (path) {
                json.file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.square_file?.file_path;
            if (path) {
                json.square_file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.first_slide_desktop_file?.file_path;
            if (path) {
                json.first_slide_desktop_file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.first_slide_mobile_file?.file_path;
            if (path) {
                json.first_slide_mobile_file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.desktop_file?.file_path;
            if (path) {
                json.desktop_file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.mobile_file?.file_path;
            if (path) {
                json.mobile_file.file_path = `${imageUrl}/${path}`;
            }

            path = json?.main_file?.file_path;
            if (path) {
                json.main_file.file_path = `${imageUrl}/${path}`;
            }

            json?.files?.forEach((item) => {
                let path = item?.file?.file_path;
                if (path) {
                    item.file.file_path = `${imageUrl}/${path}`;
                }
            });

            let salesPath = json?.sales;

            if (salesPath) {
               json.sales = salesPath.map((el) => {
                   let salesFilePath = el?.file?.file_path;
                   if (salesFilePath) {
                       return {
                           ...el,
                           file: {
                               ...el.file,
                               file_path: `${imageUrl}/${el.file.file_path}`,
                            }
                        }
                   }
                   return el;
               });
            }

            const dateTime = json?.date_time;
            const createAt = json?.create_at;

            if (dateTime) {
                json.date_time = ignoreTimezone(dateTime);
            }

            if (createAt) {
                json.create_at = ignoreTimezone(createAt);
            }

            if (resource === 'shops') {
                if (json.brands_ordered) {
                    json.brands_ordered = json.brands_ordered.sort((a, b) => a.order - b.order);
                }
            }

            return {
                data: {
                    ...json,
                    /*restaurants,
                    tags*/
                },
            }
        })
    },

    getMany: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
        console.log('params', params);

        // adjust time to correspond with UTC zone (default JSON.stringify time)
        const eventDate = params.data.event_date;
        const eventDateEnd = params.data.event_date_end;
        const dateStart = params.data.date_start;
        const dateEnd = params.data.date_end;
        const createAt = params.data.create_at;
        const publishAt = params.data.publish_at;
        const discountValue = params.data.discount;

        if (discountValue) {
            params.data.discount = Number(discountValue);
        }

        if (eventDate) {
            params.data.event_date = adjustTime(eventDate); 
        }
        if (eventDateEnd) {
            params.data.event_date_end = adjustTime(eventDateEnd); 
        }
        if (dateStart) {
            params.data.date_start = adjustTime(dateStart);
        }
        if (dateEnd) {
            params.data.date_end = adjustTime(dateEnd);
        }
        if (createAt) {
            params.data.create_at = adjustTime(createAt);
        }
        if (publishAt) {
            params.data.publish_at = adjustTime(publishAt);
        }
        if (params?.data?.locations?.length) {
            const updatedLocations = params.data.locations.map((el) => ({
                ...el,
                floor: Number(el?.floor),
                pavilion: Number(el?.pavilion),
            }));
            params.data.locations = updatedLocations;
        }

        if (resource === 'materials') {
            // remove mutually exclusive params
            const typeId = params.data.material_type_id; 
            params.data.material_type = {
                id: typeId,
                title: materialTypes[typeId],
            }

            const paramsData = params.data;

            switch (params?.data?.material_type?.title) {
                case 'Мероприятия': {
                    const {
                        news_type,
                        news_type_id,
                        article_type,
                        article_type_id,
                        article_room_type,
                        article_room_type_id,
                        date_start,
                        date_end,
                        ...rest
                    } = paramsData;

                    params.data = rest;

                    break;
                }
                case 'Статьи': {
                    const {
                        news_type,
                        news_type_id,
                        event_type,
                        event_type_id,
                        date_start,
                        date_end,
                        event_date,
                        is_pay,
                        is_registration,
                        ...rest
                    } = paramsData;

                    params.data = rest;

                    break;
                }
                case 'Новости': {
                    const {
                        event_type,
                        event_type_id,
                        article_type,
                        article_type_id,
                        article_room_type,
                        article_room_type_id,
                        event_date,
                        is_pay,
                        is_registration,
                        is_pro,
                        date_start,
                        date_end,
                        ...rest
                    } = paramsData;

                    params.data = paramsData.news_type_id !== 1 
                        ? rest
                        : { ...rest, date_start, date_end };

                    break;
                }
                default:
                    console.log(`Unknown material type: ${params?.material_type?.title}`)
                    break;
            }
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })
)
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        // adjust time to correspond with UTC zone (default JSON.stringify time)
        const eventDate = params.data.event_date;
        const eventDateEnd = params.data.event_date_end;
        const dateStart = params.data.date_start;
        const dateEnd = params.data.date_end;
        const createAt = params.data.create_at;
        const publishAt = params.data.publish_at;
        const discountValue = params.data.discount;

        if (discountValue) {
            params.data.discount = Number(discountValue);
        }

        if (eventDate) {
            params.data.event_date = adjustTime(eventDate); 
        }
        if (eventDateEnd) {
            params.data.event_date_end = adjustTime(eventDateEnd); 
        }
        if (dateStart) {
            params.data.date_start = adjustTime(dateStart);
        }
        if (dateEnd) {
            params.data.date_end = adjustTime(dateEnd);
        }
        if (createAt) {
            params.data.create_at = adjustTime(createAt);
        }
        if (publishAt) {
            params.data.publish_at = adjustTime(publishAt);
        }
        if (params?.data?.locations?.length) {
            const updatedLocations = params.data.locations.map((el) => ({
                ...el,
                floor: Number(el?.floor),
                pavilion: Number(el?.pavilion),
            }));
            params.data.locations = updatedLocations;
        }

        if (resource === 'materials') {
            // remove mutually exclusive params
            const typeId = params.data.material_type_id; 
            params.data.material_type = {
                id: typeId,
                title: materialTypes[typeId],
            }

            const paramsData = params.data;

            switch (params?.data?.material_type?.title) {
                case 'Мероприятия': {
                    const {
                        news_type,
                        news_type_id,
                        article_type,
                        article_type_id,
                        article_room_type,
                        article_room_type_id,
                        date_start,
                        date_end,
                        ...rest
                    } = paramsData;

                    params.data = rest;
                    break;
                }
                case 'Статьи': {
                    const {
                        news_type,
                        news_type_id,
                        event_type,
                        event_type_id,
                        date_start,
                        date_end,
                        event_date,
                        is_pay,
                        is_registration,
                        speakers,
                        ...rest
                    } = paramsData;

                    params.data = rest;

                    break;
                }
                case 'Новости': {
                    const {
                        event_type,
                        event_type_id,
                        article_type,
                        article_type_id,
                        article_room_type,
                        article_room_type_id,
                        event_date,
                        is_pay,
                        is_pro,
                        is_registration,
                        date_start,
                        date_end,
                        speakers,
                        ...rest
                    } = paramsData;

                    params.data = paramsData.news_type_id !== 1 
                        ? rest
                        : { ...rest, date_start, date_end };

                    break;
                }
                default:
                    console.log(`Unknown material type: ${params?.material_type?.title}`)
                    break;
            }
        }
        
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};