import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    BooleanInput,
    maxLength,
} from 'react-admin';
import SimpleFormToolbar, { FormToolbarWithCustomDelete } from '../components/SimpleFormToolbar';
import ActivityButton from "../components/ActivityButton";

const Title = ({ record }) => {
    return <span>Категория {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const ProductSaleCategoryList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <TextField source="sort" label="Сортировка" />
            <ActivityButton
                {...props}
                datapath="product-sale-categories"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
}) => {
    //return in () jsx
    return (
        <>
            <TextInput source="title" label="Название" validate={[required(), maxLength(100)]} fullWidth/>
            <NumberInput source="sort" onKeyDown={(e) => ['+', '-', 'e', 'E', ',', '.'].includes(e.key) && e.preventDefault()} onWheel={(e) => e.target.blur()} label="Сортировка" validate={[required()]} fullWidth min="0"/>
            <BooleanInput source="is_active" defaultValue={true} label="Активность" />
        </>
    );
}

export const ProductSaleCategoryEdit = props => {
    /*props means "name='123' fdsgdfg='dfgdfhgfgh'"*/
    return <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<FormToolbarWithCustomDelete />}>
            <MySimpleForm type='update'/>
        </SimpleForm>
    </Edit>
};

export const ProductSaleCategoryCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);