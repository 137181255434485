import { Node } from '@tiptap/core';

export default Node.create({
  name: 'image-container',

  group: 'block',

  content: 'inline*',

  selectable: true,

  addAttributes() {
    return {
      class: {
        default: 'single-image',
      },
      src: {
        default: '',
      },
      title: {
        default: '',
      },
      'data-name': {
        default: '',
      },
      'data-copyright': {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[class="single-image"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setAddImage: (options) => ({ commands }) => {
        return commands.insertContent([
          {
            type: this.name,
            attrs: options,
          },
        ]);
      },
    }
  }
});
