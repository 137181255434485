import * as React from "react";
import { Admin, Resource } from 'react-admin';

import authProvider from './providers/authProvider';
import dataProvider from './providers/uploadPictureDataProvider';

import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import AddAlertIcon from '@mui/icons-material/AddAlert';

import { Layout } from './layout';

import { BannerList, BannerEdit, BannerCreate } from './crud/banner';
import { TagList, TagEdit, TagCreate } from './crud/tag';
import { SliderList, SliderEdit, SliderCreate } from './crud/slider';
import { CategoryList, CategoryEdit } from './crud/category';
import { PageList, PageEdit } from './crud/page';
import { SubscriberList, SubscriberShow } from './crud/subscriber';
import { MaterialList, MaterialEdit, MaterialCreate } from './crud/material';
import { SpeakerList, SpeakerEdit, SpeakerCreate } from './crud/speaker';
import { BrandList, BrandEdit } from './crud/brand';
import { ShopList, ShopEdit } from './crud/shop';
import { ServiceList, ServiceEdit, ServiceCreate } from './crud/service';
import { RestaurantList, RestaurantEdit, RestaurantCreate } from './crud/restaurant';
import { WebFormList, WebFormShow } from './crud/webForm';
import { ProductList, ProductEdit, ProductCreate } from './crud/product';
import { ProductSaleCategoryList, ProductSaleCategoryEdit, ProductSaleCategoryCreate } from './crud/productSaleCategory';
import { MarketingCollectionList, MarketingCollectionEdit, MarketingCollectionCreate } from './crud/marketingCollection';
import { MarketingCampaignList, MarketingCampaignEdit } from './crud/marketingCampaign';
import { SettingList, SettingEdit } from './crud/setting';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const tenMinutesInMilliseconds = 600000;
const refreshTimeout = tenMinutesInMilliseconds;

const refreshAuth = () => {
    setTimeout(() => {
        authProvider.checkAuth();
        refreshAuth();
    }, refreshTimeout)
};

refreshAuth();

const App = () => (
    <Admin authProvider={authProvider} dataProvider={dataProvider} layout={Layout} i18nProvider={i18nProvider}>
        <Resource name="settings" list={SettingList} edit={SettingEdit} icon={SettingsIcon}  options={{ label: 'Настройки' }} />

        <Resource name="tags" list={TagList} edit={TagEdit} create={TagCreate} icon={LocalOfferIcon}  options={{ label: 'Теги' }} />
        <Resource name="banners" list={BannerList} edit={BannerEdit} create={BannerCreate} icon={InsertPhotoIcon} options={{ label: 'Баннеры' }} />
        <Resource name="sliders" list={SliderList} edit={SliderEdit} create={SliderCreate} icon={BurstModeIcon}  options={{ label: 'Слайдеры' }} />

        <Resource name="pages" list={PageList} edit={PageEdit} icon={HomeIcon} options={{ label: 'Контентные страницы' }} />

        <Resource name="categories" list={CategoryList} edit={CategoryEdit}  options={{ label: 'Рубрикатор каталога' }} />
        <Resource name="shops" list={ShopList} edit={ShopEdit} icon={ShoppingBasketIcon} options={{ label: 'Магазины' }} />
        <Resource name="brands" list={BrandList} edit={BrandEdit} icon={BrandingWatermarkIcon} options={{ label: 'Бренды' }} />
        <Resource name="materials" list={MaterialList} edit={MaterialEdit} create={MaterialCreate} icon={DescriptionIcon} options={{ label: 'События' }} />
        <Resource name="speakers" list={SpeakerList} edit={SpeakerEdit} create={SpeakerCreate} icon={KeyboardVoiceIcon} options={{ label: 'Спикеры' }} />
        <Resource name="service" options={{ label: 'Услуги', "isMenuParent": true }} />

        <Resource name="services" list={ServiceList} edit={ServiceEdit} create={ServiceCreate} options={{ label: 'Услуги', "menuParent": "service" }} />
        <Resource name="restaurants" list={RestaurantList} edit={RestaurantEdit} create={RestaurantCreate} options={{ label: 'Рестораны', "menuParent": "service" }} />

        <Resource name="marketing" options={{ label: 'Маркетинг', "isMenuParent": true }} />
        <Resource name="marketing-campaigns" list={MarketingCampaignList} edit={MarketingCampaignEdit} options={{ label: 'Маркетинговая акция', "menuParent": "marketing" }} />

        <Resource name="products" list={ProductList} edit={ProductEdit} create={ProductCreate} options={{ label: 'Товары', "menuParent": "marketing" }} />
        <Resource name="product-sale-categories" list={ProductSaleCategoryList} edit={ProductSaleCategoryEdit} create={ProductSaleCategoryCreate} options={{ label: 'Категории', "menuParent": "marketing" }} />
        <Resource name="marketing-collections" list={MarketingCollectionList} edit={MarketingCollectionEdit} create={MarketingCollectionCreate} options={{ label: 'Коллекции', "menuParent": "marketing" }} />

        <Resource name="subscribers" list={SubscriberList} show={SubscriberShow} icon={AddAlertIcon} options={{ label: 'Подписка на рассылку' }} />
        <Resource name="web-forms" list={WebFormList} show={WebFormShow} icon={AccountBoxIcon} options={{ label: 'Веб формы' }} />
        

        {/*без этой херни не работает ReferenceArrayInput*/}
        <Resource name="material-types"/* list={MaterialTypeList} icon={PostIcon}  options={{ label: 'Типы материлов' }}*/ />
        <Resource name="news-types"/* list={NewsTypeList} icon={PostIcon}  options={{ label: 'Типы новостей' }}*/ />
        <Resource name="article-types"/* list={ArticleTypeList} icon={PostIcon}  options={{ label: 'Типы статей' }}*/ />
        <Resource name="article-room-types"/* list={ArticleTypeList}  icon={PostIcon}  options={{ label: 'Типы помещения для статей' }}*/ />
        <Resource name="event-types"/* list={EventTypeList} icon={PostIcon}  options={{ label: 'Типы мероприятий' }}*/ />
        <Resource name="feed-types"/* list={FeedTypeList} icon={PostIcon} options={{ label: 'Типы ленты событий' }}*/ />
    </Admin>
);

export default App;