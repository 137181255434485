import { Mark, mergeAttributes } from '@tiptap/core';

const AddSup = Mark.create({
  name: 'supMark',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      { tag: 'sup' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "sup",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addCommands() {
    return {
      toggleAddSup: () => ({ commands }) => {
        return commands.toggleMark(this.name);
      },
    }
  }
});

export default AddSup;
