import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useNotify,
    FormWithRedirect,
    useDataProvider,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function TagQuickCreateButton({ onChange }) {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tagName, setTagName] = useState('');
    const notify = useNotify();
    const form = useForm();
    const dataProvider = useDataProvider();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleInput = (e) => {
        const input = e?.target?.value;
        setTagName(input);
    }

    const handleSubmit = () => {
        setLoading(true);
        dataProvider.create('tags', { data: { title: tagName } })
            .then(({ data }) => {
                const { id } = data;
                const tags = form?.getFieldState('tags')?.value || [];
                tags.push(id);
                form?.change('tags', tags);
                onChange();
                setLoading(false);
                setShowDialog(false);
            })
            .catch(() => {
                notify(['Произошла ошибка'], { type: 'error' });
                setLoading(false);
                setShowDialog(false);
            });
    };

    return (
        <>
            <Button onClick={handleClick} label="Добавить тег">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Добавить тег"
            >
                <DialogTitle>Добавить тег</DialogTitle>

                <FormWithRedirect
                    resource="tags"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    source="tag_title"
                                    validate={required()}
                                    onChange={handleInput}
                                    fullWidth
                                    label="Тег"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default TagQuickCreateButton;
