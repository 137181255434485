import { Mark, mergeAttributes } from '@tiptap/core';

const AddSmallLetters = Mark.create({
  name: 'spanSmallLetters',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      class: {
        default: "small-letters"
      }
    };
  },

  parseHTML() {
    return [
      { tag: 'span[class="small-letters"]' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addCommands() {
    return {
      toggleAddSmallLetters: () => ({ commands }) => {
        return commands.toggleMark(this.name);
      },
    }
  }
});

export default AddSmallLetters;
