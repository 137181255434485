import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';
import SimpleFormToolbar from '../components/SimpleFormToolbar';

const Title = ({ record }) => {
    return <span>Тег {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const TagList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" sortable={false}/>
            <TextField source="title" label="Название" sortable={false}/>
            <EditButton label="" />
        </Datagrid>
    </List>
);

export const TagEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <TextInput source="title" label="Название" />
        </SimpleForm>
    </Edit>
);

export const TagCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <TextInput source="title" label="Название" />
        </SimpleForm>
    </Create>
);