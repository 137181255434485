import * as React from 'react';
import { useEffect } from 'react';
import './componentStyles.css';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const ModalWindow = ({
  children, modalIsOpened, setModalIsOpened
}) => {
  const handleClose = () => setModalIsOpened(false);

  useEffect(() => {
    if (modalIsOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => document.body.style.overflow = "auto"; 
  }, [modalIsOpened]);

  return (
    <div
      className="modal-window"
      hidden={!modalIsOpened}
    >
      <button
        type="button"
        className="modal-close"
        onClick={handleClose}
      >
        <CancelPresentationIcon />
        <span className="modal-close__description">Закрыть</span>
      </button>
      {children}
    </div>
  )
};

export default ModalWindow;
