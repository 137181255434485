import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import SimpleFormToolbar from '../components/SimpleFormToolbar';

const Title = ({ record }) => {
    return <span>Настройка {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
];

export const SettingList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" sortable={false}/>
            <TextField source="title" label="Название" sortable={false}/>
            <TextField source="value" label="Значение"  sortable={false} />
            <EditButton label="" />
        </Datagrid>
    </List>
);

export const SettingEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar candelete="false"/>}>
            <TextField source="title" label="Название"/>
            <TextInput source="value" label="Значение" fullWidth/>
        </SimpleForm>
    </Edit>
);