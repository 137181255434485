/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required,
    maxLength,
} from 'react-admin';
import { useForm } from 'react-final-form';

import Editor from "../components/Editor/Editor";
import ActivityButton from "../components/ActivityButton";
import SimpleFormToolbar from '../components/SimpleFormToolbar';
import { validateImageWidth, validateImageSize, onImageDrop } from '../utils/imageValidator';
import { itemsPerPage } from "../utils/variables";

const Title = ({ record }) => {
    return <span>Коллекция {record ? `"${record.title}"` : ''}</span>;
};

const Filters = [
    <TextInput source="title" label="Поиск" alwaysOn />,
    /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
        <SelectInput optionText="name" />
    </ReferenceInput>,*/
];

export const MarketingCollectionList = props => (
    <List filters={Filters} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" label="Название" />
            <ActivityButton
                {...props}
                datapath="marketing-collections"
                label="Активность"
            />
            <EditButton label="" />
        </Datagrid>
    </List>
);

const MySimpleForm = ({
    type = 'create',
    ...rest
}) => {
    const [description, setDescription] = useState(rest?.record?.description);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageSize, setImageSize] = useState(0);

    const handleImageDrop = (e) => onImageDrop(e, setImageWidth, setImageSize);

    const imageWidthValidator = () => validateImageWidth(
        imageWidth,
        [976],
        'Изображение не может быть шире 976 пикселей'
    );

    const imageSizeValidator = () => validateImageSize(
        imageSize,
        500000,
        'Изображение не может весить более 500кб'
    );

    const imageValidator = [imageWidthValidator, imageSizeValidator];
    const form = useForm();

    useEffect(() => {
        form.change('description', description);
    }, [description]);
    //return in () jsx
    return (
        <>
            <TextInput source="title" label="Название" validate={[required(), maxLength(100)]} fullWidth/>
            <Editor text={rest?.record?.description} setText={setDescription} label="Описание" />
            <BooleanInput source="is_active" defaultValue={true} label="Активность" fullWidth/>

            <ReferenceInput label="Магазин" reference="shops" source="shop_id" perPage={itemsPerPage} allowEmpty emptyText="— Очистить —" emptyValue={null} validate={required()} fullWidth>
                <SelectInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <ImageInput source="picture_square" label="Загрузка изображения" accept="image/jpeg,image/png" onChange={handleImageDrop} placeholder={<p>Перетащите сюда изображение размером <b>976*552px</b> весом до <b>500кб</b></p>} validate={type === 'create' ? [required(), imageValidator] : [imageValidator]}>
                <ImageField source="src_square" label="Изображение" />
            </ImageInput>


            {type === 'update' && <ImageField source="file.file_path" label="Изображение" />}
        </>
    );
}


export const MarketingCollectionEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm type='update' />
        </SimpleForm>
    </Edit>
);

export const MarketingCollectionCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<SimpleFormToolbar />}>
            <MySimpleForm />
        </SimpleForm>
    </Create>
);
