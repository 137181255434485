import { Mark, mergeAttributes } from '@tiptap/core';

const AddClass = Mark.create({
  name: 'yellowCircleClass',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      class: {
        default: "yellow-circle"
      }
    };
  },

  parseHTML() {
    return [
      { tag: 'span[class="yellow-circle"]' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addCommands() {
    return {
      setAddClass: () => ({ commands }) => {
        return commands.setMark(this.name);
      },
      toggleAddClass: () => ({ commands }) => {
        return commands.toggleMark(this.name);
      },
      unsetAddClass: () => () => ({ commands }) => {
        return commands.unsetMark(this.name)
      },
    }
  }
});

export default AddClass;
